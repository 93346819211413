import { useState } from "react";

import "moment/locale/pt-br";

import { Stack, Button } from "@mui/material";

/* hooks */
//import useFlashMessage from "../../../hooks/useFlashMessage";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

import Input from "../../form/Input";

import formStyles from "../../form/Form.module.css";

function Form({ token, handleSubmit, formData, btnText, caminhoVoltar }) {
    const [dados, setUf] = useState(formData || "");
    const [preview, setPreview] = useState([]);
    //const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();

    async function onFileChange(e) {
        setPreview(Array.from(e.target.files));
        setUf({ ...dados, bandeira: [...e.target.files] });
    }
    function handleChange(e) {
        setUf({ ...dados, [e.target.name]: e.target.value });
    }
    function handleOnBlur(e) {}
    function handleOnFocus(e) {}

    const submit = (e) => {
        e.preventDefault();
        if (btnText === "Cadastrar") {
            dados.createdUser = parseInt(localStorage.getItem("pessoaId"));
            dados.updatedUser = parseInt(localStorage.getItem("pessoaId"));
        }

        if (btnText === "Salvar") {
            dados.updatedUser = parseInt(localStorage.getItem("pessoaId"));
        }

        handleSubmit(dados);
    };

    return (
        <form onSubmit={submit}>
            <h1>Estados</h1>
            <div className={formStyles.preview_bandeira}>
                <div>
                    <Input text="Bandeira do Estado" type="file" name="bandeira" handleOnChange={onFileChange} multiple={false} />
                </div>
                {preview.length > 0
                    ? preview.map((image, index) => (
                          <img
                              src={URL.createObjectURL(image)}
                              alt={dados.bandeira}
                              key={`${dados.bandeira}+${index}`}
                              style={{
                                  borderColor: "black",
                                  border: "solid 0.05em",
                              }}
                              width={150}
                              height={100}
                          />
                      ))
                    : dados.bandeira && (
                          <img
                              src={process.env.REACT_APP_API + "imagens/bandeiras/" + dados.bandeira}
                              alt={dados.uf}
                              style={{
                                  borderColor: "black",
                                  border: "solid 0.05em",
                              }}
                              width={150}
                              height={100}
                          />
                      )}
            </div>
            <div>
                <Input
                    text="Código"
                    type="text"
                    name="id"
                    placeholder="Código do Estado"
                    title="Digite o código do Estado confome IBGE"
                    minlength={6}
                    maxlength={18}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.id || ""}
                    required
                />
                <Input
                    text="Estado"
                    type="text"
                    name="descricao"
                    placeholder="nome do Estado"
                    title="Digite o nome do Estado"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.descricao || ""}
                    required
                />
                <Input
                    text="UF"
                    type="text"
                    name="uf"
                    placeholder="UF do Estado"
                    title="Digite a UF do Estado"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.uf || ""}
                    required
                />
            </div>

            <div>
                <Stack direction="row" spacing={1}>
                    <Button variant="contained" color="secondary" onClick={() => navigate(caminhoVoltar)}>
                        Voltar
                    </Button>
                    <Button type="submit" variant="contained" color="success">
                        {btnText}
                    </Button>
                </Stack>
            </div>
        </form>
    );
}

export default Form;
