import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Context } from "../../../context/UserContext";
import Form from "./Form";
import styles from "../../form/Form.module.css";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";

import * as EmpresaAPI from "../../../api/empresa";
import CrudsAPI from "../../../api/CRUDs";

const CreateOrEdit = () => {
    const [dados, setDados] = useState({});
    const [token] = useState(localStorage.getItem("token")) || "";
    const { parametro } = useContext(Context);
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);

    const uuid = urlParams.get("uuid");

    let btnText = "";
    !uuid ? (btnText = "Cadastrar") : (btnText = "Atualizar");

    //enums e tabelas
    const [OptionsEmpresa, setEmpresa] = useState([]);
    const [OptionsUnidade, setUnidade] = useState([]);

    //carregar API's Externas
    const consultaEmpresa = async () => {
        let result = await EmpresaAPI.empresa(localStorage.getItem("empresaId"));
        setEmpresa(result);
    };
    const consultaUnidade = async () => {
        let result = await EmpresaAPI.unidade(localStorage.getItem("empresaId"), false, false, false, false);
        setUnidade(result);
    };

    useEffect(() => {
        if (!token) {
            return navigate("/Auth", { replace: true });
        }

        consultaEmpresa();
        consultaUnidade();

        if (uuid) {
            //consulta a API
            CrudsAPI.embalagem
                .consulta(uuid)
                .then((response) => {
                    setDados(response.data);
                })
                .catch((error) => {
                    console.error(error);
                    setFlashMessage(error.response.data.message, error.response.data.type);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, uuid]);

    async function managerDados(dados) {
        let urlNavigate = "";

        try {
            let response;

            if (uuid) {
                response = await CrudsAPI.embalagem.editar(dados);
            } else {
                response = await CrudsAPI.embalagem.cadastrar(dados);

                if (response.status === 202) {
                    setDados(response.data);
                    response.data.message = "Registro em duplicidade!";
                    response.data.type = "warning";
                    urlNavigate = `/Embalagens/CreateOrEdit?uuid=${response.data.uuid}`;
                } else {
                    urlNavigate = "/Embalagens";
                }
            }

            setFlashMessage(response.data.message, response.data.type);

            setTimeout(() => {
                if (response.data.type === "success") {
                    navigate("/Embalagens");
                } else {
                    navigate(urlNavigate);
                }
            }, parametro.msgTempo * 1000);
        } catch (error) {
            console.error(error);
            setFlashMessage(error.response.data.message, error.response.data.type);
        }
    }
    return (
        <section className={`${styles.form_container} ${styles[parametro.tema]}`}>
            <Form
                token={token}
                handleSubmit={managerDados}
                formData={dados}
                btnText={btnText}
                OptionsEmpresa={OptionsEmpresa}
                OptionsUnidade={OptionsUnidade}
                caminhoVoltar={`/Embalagens`}
            />
        </section>
    );
};
export default CreateOrEdit;
