import { useState, useEffect } from "react";

import { Stack, Button } from "@mui/material";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

import * as TabelaAPI from "../../../api/tabela";

import Input from "../../form/Input";
import Checkbox from "../../form/Checkbox";

function Form({ token, handleSubmit, formData, btnText, caminhoVoltar }) {
    const [dados, setDados] = useState({});
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();

    useEffect(() => {
        setDados(formData || {});
    }, [formData]);

    function handleChange(e) {
        if (e.target.name === "ativo" || e.target.name === "administrativo") {
            setDados({ ...dados, [e.target.name]: e.target.checked });
        } else {
            setDados({ ...dados, [e.target.name]: e.target.value });
        }
    }
    async function handleOnBlur(e) {}
    function handleOnFocus(e) {}

    const submit = (e) => {
        e.preventDefault();
        handleSubmit(dados);
    };

    return (
        <form onSubmit={submit}>
            <h1>Gestão de Menus</h1>
            <div>
                <Input
                    text="Menu"
                    type="text"
                    name="descricao"
                    placeholder="nome do Menu"
                    title="Digite o nome do Menu"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.descricao || ""}
                    required
                />
                <Input
                    text="Título"
                    type="text"
                    name="title"
                    placeholder="Título"
                    title="Digite o título que aparecerá quando o mouse estiver no menu"
                    inputWidth="350px"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.title || ""}
                />
            </div>
            <div>
                <Checkbox
                    title="Quando marcado informa que o menu é do modulo da administração,
                    funciona para empresa que o paramêtro tabelas comuns estiver desmarcado."
                    text="Administrativo"
                    name="administrativo"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.administrativo}
                    checked={dados.administrativo === true ? "checked" : ""}
                />
                <Checkbox
                    title="Quando marcado o menu esta ATIVO"
                    text="Ativo"
                    name="ativo"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.ativo}
                    checked={dados.ativo === true ? "checked" : ""}
                />
            </div>
            <div>
                <Stack direction="row" spacing={1}>
                    <Button variant="contained" color="secondary" onClick={() => navigate(caminhoVoltar)}>
                        Voltar
                    </Button>
                    <Button type="submit" variant="contained" color="success">
                        {btnText}
                    </Button>
                </Stack>
            </div>
        </form>
    );
}

export default Form;
