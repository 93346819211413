import React, { useEffect, useMemo, useState, useContext } from "react";
import { Context } from "../../../context/UserContext";

import MaterialReactTable from "material-react-table";

import { ThemeProvider, useTheme } from "@mui/material";
/* tema customizado*/
import tableThemeCustom from "../../form/tableThemeCustom";

//Import Material React Table Translations
import { MRT_Localization_PT_BR } from "material-react-table/locales/pt-BR";
import CustomDialog from "../../layout/CustomDialog";

import { Box, Button, Checkbox, IconButton, Tooltip } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { useNavigateParams } from "../../../hooks/useNavigateParams";
import tratar from "../../../utils/retornaTratamentoURL";
import CRUDsAPI from "../../../api/CRUDs";

/* util */
import { obterPermissoesUsuario } from "../../../utils/funcoes";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";

const Index = () => {
    const [dados, setDados] = useState([]);
    const [token] = useState(localStorage.getItem("token")) || "";
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams(); //const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);

    const ul = urlParams.get("ul") || 1;
    const li = urlParams.get("li") || 11;
    const tipo = urlParams.get("tipo");

    const { parametro } = useContext(Context);
    /* Obtem a paginação padrão */
    useEffect(() => {
        if (parametro && parametro.paginacao) {
            setPagination({
                pageIndex: 0,
                pageSize: parametro.paginacao,
            });
        }
    }, [parametro]);

    //dialog customizado
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});

    //data and fetching state
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    //tratar filtros se vier pela navbar
    if (localStorage.path.replace(/["]/g, "") !== window.location.pathname) {
        tratar.LimparFiltros();

        localStorage.setItem("path", JSON.stringify(window.location.pathname));
    }

    //table state
    const [columnFilterFns, setColumnFiltersMode] = useState(
        localStorage.columnFilterFns.length > 2 ? JSON.parse(localStorage.columnFilterFns) : []
    );

    const [columnFilters, setColumnFilters] = useState(
        localStorage.columnFilters.length > 2 ? JSON.parse(localStorage.columnFilters) : []
    );

    const [globalFilter, setGlobalFilter] = useState(
        localStorage.globalFilter.length > 2 ? JSON.parse(localStorage.globalFilter) : ""
    );

    const [sorting, setSorting] = useState(localStorage.sorting.length > 2 ? JSON.parse(localStorage.globalFilter) : []);

    const [pagination, setPagination] = useState({
        pageIndex: JSON.parse(localStorage.pagination).pageIndex || 0,
        pageSize: JSON.parse(localStorage.pagination).pageSize || 10,
    });

    //const [tableData, setTableData] = useState(() => dados);
    //const [validationErrors, setValidationErrors] = useState({});

    const fetchData = async () => {
        if (!dados.length) {
            setIsLoading(true);
        } else {
            setIsRefetching(true);
        }

        try {
            CRUDsAPI.pessoaEmpresa
                .listar(
                    pagination.pageIndex * pagination.pageSize,
                    pagination.pageSize,
                    JSON.stringify(columnFilters ?? []),
                    JSON.stringify(columnFilterFns ?? []),
                    globalFilter ?? "",
                    JSON.stringify(sorting ?? [])
                )
                .then((response) => {
                    setDados(response.data.data);
                    setRowCount(response.data.meta.totalRowCount);
                });
        } catch (error) {
            setIsError(true);
            console.error(error);
            return;
        }
        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
    };

    //funções do dialog
    const handleOpenDialog = (row) => {
        setOpenDialog(true);
        setSelectedRow(row);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleConfirmDialog = async (row) => {
        // Verifique se há uma linha selecionada
        let msgType = "success";
        if (selectedRow) {
            try {
                const response = await CRUDsAPI.pessoaEmpresa.deletar(selectedRow);

                setFlashMessage(response.data.message, msgType);

                // Após a exclusão, chame a função fetchData() novamente para atualizar as unidades
                fetchData();
            } catch (error) {
                msgType = "error";
                console.error(error);
                setFlashMessage(error.response.data.message, msgType);
            }
        }
        setOpenDialog(false);
    };

    useEffect(() => {
        if (!token) {
            return navigate("/Auth", { replace: true });
        }

        //funcao para setar localStorage
        localStorage.setItem(
            "pagination",
            JSON.stringify({
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize,
            })
        );
        localStorage.setItem("columnFilterFns", JSON.stringify(columnFilterFns));
        localStorage.setItem("columnFilters", JSON.stringify(columnFilters));
        localStorage.setItem("globalFilter", JSON.stringify(globalFilter));
        localStorage.setItem("sorting", JSON.stringify(sorting));

        //permissões do usuario
        obterPermissoesUsuario(localStorage.getItem("pessoaId"), localStorage.getItem("empresaId"), ul, li);

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, columnFilterFns, columnFilters, globalFilter, pagination.pageIndex, pagination.pageSize, sorting, ul, li]);

    const columns = useMemo(
        () => [
            {
                id: "id",
                header: "Relação entre Pessoas e Empresas",
                columns: [
                    {
                        accessorKey: "Pessoa.CPForCNPJ",
                        header: "CPF ou CNPJ",
                        Cell: ({ renderedCellValue, row }) => {
                            return row.original.Pessoa.CPForCNPJ;
                        },
                    },
                    {
                        accessorKey: "Pessoa.nomePrincipal",
                        header: "Nome Principal",
                        Cell: ({ renderedCellValue, row }) => {
                            return row.original.Pessoa.nomePrincipal;
                        },
                    },
                    {
                        accessorKey: "Empresa.razaoSocial",
                        header: "Empresa",
                        //you can access a cell in many callback column definition options like this
                        Cell: ({ renderedCellValue, row }) => {
                            return row.original.Empresa.razaoSocial;
                        },
                    },
                    {
                        accessorKey: "Empresa.tipo",
                        header: "Tipo",
                        //you can access a cell in many callback column definition options like this
                        Cell: ({ renderedCellValue, row }) => {
                            return row.original.Empresa.tipo;
                        },
                    },
                    {
                        accessorKey: "ativo",
                        header: "Ativo",
                        type: "boolean",
                        size: 40,
                        align: "center",
                        columnFilterModeOptions: ["equals"],
                        filterSelectOptions: [
                            { text: "Todos", value: "" },
                            { text: "Marcado", value: 1 },
                            { text: "Desmarcado", value: 0 },
                        ],
                        filterVariant: "select",

                        muiTableBodyCellProps: ({ row }) => ({
                            align: "center",
                            onClick: () => {},
                        }),
                        Cell: ({ renderedCellValue, row }) => {
                            return (
                                <Checkbox
                                    checked={row.original.ativo}
                                    sx={{
                                        "& .MuiSvgIcon-root": { fontSize: 28 },
                                    }}
                                    inputProps={{ "aria-label": "controlled" }}
                                />
                            );
                        },
                    },
                ],
            },
        ],
        []
    );

    /* modo tema */
    const theme = tableThemeCustom();

    return (
        <div>
            <div>
                {openDialog && (
                    <CustomDialog
                        title="Delete relacionamento de Pessoa com a Empresa!"
                        content="Você tem certeza?"
                        onClose={handleCloseDialog}
                        onConfirm={handleConfirmDialog}
                    />
                )}
            </div>
            <ThemeProvider theme={theme}>
                <MaterialReactTable
                    displayColumnDefOptions={{
                        "mrt-row-actions": {
                            muiTableHeadCellProps: {
                                align: "center",
                            },
                            size: 80,
                        },
                        "mrt-row-numbers": {
                            enableColumnOrdering: true, //turn on some features that are usually off
                            enableResizing: true,
                            muiTableHeadCellProps: {
                                sx: {
                                    fontSize: "1.2rem",
                                },
                            },
                        },
                        "mrt-row-select": {
                            enableColumnActions: true,
                            enableHiding: true,
                            size: 50,
                        },
                    }}
                    enableColumnFilterModes
                    enableGlobalFilterModes
                    enableColumnResizing
                    enableColumnOrdering
                    enableEditing
                    renderRowActions={({ row, table }) => (
                        <Box sx={{ display: "flex", gap: "1rem" }}>
                            <Tooltip arrow placement="left" title="Editar">
                                <span>
                                    {" "}
                                    <IconButton
                                        onClick={() =>
                                            navigate("/PessoaEmpresa/CreateOrEdit", {
                                                uuid: row.id,
                                                ul: ul,
                                                li: li,
                                                tipo: tipo,
                                                btnText: urlParams.get("btnText"),
                                            })
                                        }
                                        disabled={JSON.parse(localStorage.getItem("editar")) === false}
                                    >
                                        <Edit />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip arrow placement="right" title="Deletar">
                                <span>
                                    <IconButton
                                        color="error"
                                        onClick={() => handleOpenDialog(row)}
                                        disabled={JSON.parse(localStorage.getItem("cancelar")) === false}
                                    >
                                        <Delete />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </Box>
                    )}
                    renderTopToolbarCustomActions={() => (
                        <div
                            style={{
                                display: "flex",
                                gap: "8px",
                            }}
                        >
                            <Tooltip arrow placement="top-end" title={`Novo Registro`}>
                                <div>
                                    <Button
                                        color="secondary"
                                        onClick={() =>
                                            navigate("/PessoaEmpresa/CreateOrEdit", {
                                                ul: ul,
                                                li: li,
                                                tipo: tipo,
                                            })
                                        }
                                        variant="contained"
                                        disabled={false}
                                    >
                                        Novo
                                    </Button>
                                </div>
                            </Tooltip>
                        </div>
                    )}
                    columns={columns}
                    data={dados}
                    enableRowSelection
                    getRowId={(row) => row.uuid}
                    manualFiltering
                    manualPagination
                    manualSorting
                    muiToolbarAlertBannerProps={
                        isError
                            ? {
                                  color: "error",
                                  children: "Error loading data",
                              }
                            : undefined
                    }
                    onColumnFiltersChange={setColumnFilters}
                    onColumnFilterFnsChange={setColumnFiltersMode}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    rowCount={rowCount}
                    initialState={{
                        showColumnFilters: true,
                        density: "compact",
                        //columnPinning: { left: ["mrt-row-select"] },
                    }}
                    state={{
                        columnFilterFns,
                        columnFilters,
                        globalFilter,
                        isLoading,
                        pagination,
                        showAlertBanner: isError,
                        showProgressBars: isRefetching,
                        sorting,
                    }}
                    //positionToolbarDropZone={"top"}
                    //positionExpandColumn={"last"}
                    positionActionsColumn={"first"}
                    positionToolbarAlertBanner="bottom"
                    localization={MRT_Localization_PT_BR}
                />
            </ThemeProvider>
        </div>
    );
};
export default Index;
