import { useState, useEffect } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "moment/locale/pt-br";

import { Stack, Button } from "@mui/material";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";
import { useNavigateParams } from "../../../hooks/useNavigateParams";
import * as TabelaAPI from "../../../api/tabela";

import Input from "../../form/Input";
import InputNumber from "../../form/InputNumber";
import TextArea from "../../form/TextArea";
import Checkbox from "../../form/Checkbox";
import SelectEmpresa from "../../form/SelectEmpresa";
import Select from "../../form/Select";

/* utils */
import { formatarNumero, formatarNumeroDecimal } from "../../../utils/masks";

function Form({ token, handleSubmit, formData, btnText, caminhoVoltar, OptionsEmpresa }) {
    const [dados, setDados] = useState(formData || {});
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();

    if (!dados.ativo) {
        dados.ativo = true;
    }

    useEffect(() => {
        setDados(formData || {});
    }, [formData]);

    function handleChange(e) {
        let valor = e.target.type === "checkbox" ? e.target.checked : e.target.value;

        setDados({ ...dados, [e.target.name]: valor });
    }

    async function handleOnBlur(e) {
        //verifica duplicidade
        if (e.target.name === "descricao" && btnText === "Cadastrar") {
            const duplicidade = await TabelaAPI.duplicidadeDescricao(
                document.getElementById("empresaId")?.value || 1,
                "Situacoes",
                e.target.value
            );

            if (duplicidade?.uuid) {
                setFlashMessage("Registro em duplicidade!", "warning");
                return navigate(`/Ambiente/CreateOrEdit`, {
                    uuid: duplicidade.uuid,
                });
            }
        }
    }
    function handleOnFocus(e) {}

    const submit = (e) => {
        e.preventDefault();

        handleSubmit(dados);
    };

    return (
        <Tabs>
            {" "}
            <form onSubmit={submit}>
                <h1>Situação</h1>
                <div>
                    <SelectEmpresa
                        text="Selecione a Empresa"
                        name="empresaId"
                        options={OptionsEmpresa}
                        handleOnChange={handleChange}
                        handleOnFocus={handleOnFocus}
                        handleOnBlur={handleOnBlur}
                        value={dados.empresaId || ""}
                        required
                        notOptNull={true}
                    />
                    <Input
                        text="Descrição"
                        type="text"
                        name="descricao"
                        placeholder="Situação"
                        title="Digite o nome do situação"
                        handleOnChange={handleChange}
                        handleOnFocus={handleOnFocus}
                        handleOnBlur={handleOnBlur}
                        value={dados.descricao || ""}
                        required={true}
                    />
                </div>
                <div>
                    {" "}
                    <TextArea
                        text="Título"
                        type="text"
                        name="titulo"
                        placeholder="Texto que auxilia o usuário a compreender o campo"
                        title="Digite o título"
                        handleOnChange={handleChange}
                        handleOnFocus={handleOnFocus}
                        handleOnBlur={handleOnBlur}
                        value={dados.titulo || ""}
                        textAlign="left"
                        linhas="4"
                        //colunas="122"
                    />
                </div>
                <div>
                    <Checkbox
                        title="Modulo Financeiro"
                        text="Financeiro"
                        name="financeiro"
                        type="checkbox"
                        handleOnChange={handleChange}
                        value={dados.financeiro}
                        checked={dados.financeiro === true ? "checked" : ""}
                    />
                    <Checkbox
                        title="Modulo Indústria"
                        text="Indústria"
                        name="industria"
                        type="checkbox"
                        handleOnChange={handleChange}
                        value={dados.industria}
                        checked={dados.industria === true ? "checked" : ""}
                    />
                    <Checkbox
                        title="Modulo Lançamentos Financeiros"
                        text="Lançamento"
                        name="lancamento"
                        type="checkbox"
                        handleOnChange={handleChange}
                        value={dados.lancamento}
                        checked={dados.lancamento === true ? "checked" : ""}
                    />
                    <Checkbox
                        title="Modulo Locação"
                        text="Locação"
                        name="locacao"
                        type="checkbox"
                        handleOnChange={handleChange}
                        value={dados.locacao}
                        checked={dados.locacao === true ? "checked" : ""}
                    />
                    <Checkbox
                        title="Modulo Logística"
                        text="Logística"
                        name="logistica"
                        type="checkbox"
                        handleOnChange={handleChange}
                        value={dados.logistica}
                        checked={dados.logistica === true ? "checked" : ""}
                    />
                    <Checkbox
                        title="Modulo Movimento"
                        text="Movimento"
                        name="movimento"
                        type="checkbox"
                        handleOnChange={handleChange}
                        value={dados.movimento}
                        checked={dados.movimento === true ? "checked" : ""}
                    />
                    <Checkbox
                        title="Modulo Bens"
                        text="Inventario"
                        name="inventario"
                        type="checkbox"
                        handleOnChange={handleChange}
                        value={dados.inventario}
                        checked={dados.inventario === true ? "checked" : ""}
                    />
                    <Checkbox
                        title="Ativa quando marcado"
                        text="Ativo"
                        name="ativo"
                        type="checkbox"
                        handleOnChange={handleChange}
                        value={dados.ativo}
                        checked={dados.ativo === true ? "checked" : ""}
                    />
                </div>
                <div>
                    <Stack direction="row" spacing={1}>
                        <Button variant="contained" color="secondary" onClick={() => navigate(caminhoVoltar)}>
                            Voltar
                        </Button>
                        <Button type="submit" variant="contained" color="success">
                            {btnText}
                        </Button>
                    </Stack>
                </div>
            </form>
        </Tabs>
    );
}
export default Form;
