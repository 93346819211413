import { useState, useEffect } from "react";
import "react-tabs/style/react-tabs.css";
import "moment/locale/pt-br";

import { Stack, Button } from "@mui/material";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

import * as TabelaAPI from "../../../api/tabela";

import Input from "../../form/Input";
import InputNumber from "../../form/InputNumber";
import Checkbox from "../../form/Checkbox";
import SelectEmpresa from "../../form/SelectEmpresa";

/* utils */
import { formatarNumero, formatarNumeroDecimal } from "../../../utils/masks";

function Form({ token, handleSubmit, formData, btnText, caminhoVoltar, OptionsEmpresa }) {
    const [dados, setDados] = useState(formData || {});
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();

    useEffect(() => {
        setDados(formData || {});

        //define o estado inicial do objeto
        if (!dados.ativo) {
            dados.ativo = true;
        }
    }, [formData]);

    function handleChange(e) {
        if (e.target.name === "ativo") {
            setDados({ ...dados, [e.target.name]: e.target.checked });
        } else if (e.target.name === "comprimento" || e.target.name === "diametro") {
            const valorFormatado = formatarNumeroDecimal(e.target.value);
            setDados({
                ...dados,
                [e.target.name]: valorFormatado,
            });
        } else {
            setDados({ ...dados, [e.target.name]: e.target.value });
        }
    }

    async function handleOnBlur(e) {
        //verifica duplicidade
        if (e.target.name === "descricao" && btnText === "Cadastrar") {
            const duplicidade = await TabelaAPI.duplicidadeDescricao(document.getElementById("empresaId").value, "Cilindro", e.target.value);

            if (duplicidade?.uuid) {
                setFlashMessage("Registro em duplicidade!", "warning");
                return navigate(`/Cilindros/CreateOrEdit`, {
                    uuid: duplicidade.uuid,
                });
            }
        }
    }
    function handleOnFocus(e) {}

    const submit = (e) => {
        e.preventDefault();
        if (!dados.empresaId) {
            dados.empresaId = parseInt(document.getElementById("empresaId").value);
        }

        handleSubmit(dados);
    };

    return (
        <form onSubmit={submit}>
            <h1>Cilindro</h1>
            <div>
                <SelectEmpresa
                    text="Selecione a Empresa"
                    name="empresaId"
                    options={OptionsEmpresa}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.empresaId || ""}
                    required
                    notOptNull={true}
                />
                <Input
                    text="Descrição"
                    type="text"
                    name="descricao"
                    placeholder="Cilindro"
                    title="Digite o nome do cilindro"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.descricao || ""}
                    required={true}
                />
                <InputNumber
                    text="Comprimento"
                    type="text"
                    name="comprimento"
                    placeholder="Comprimento"
                    inputWidth="100px"
                    title="digite o comprimento do cilindro"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.comprimento ? formatarNumero(dados.comprimento) : ""}
                    required={true}
                />
                <InputNumber
                    text="Diâmetro"
                    type="text"
                    name="diametro"
                    placeholder="Diâmetro"
                    inputWidth="100px"
                    title="digite o diametro do cilindro"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.diametro ? formatarNumero(dados.diametro) : ""}
                    required={true}
                />

                <Checkbox
                    title="Ativa quando marcado"
                    text="Ativo"
                    name="ativo"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.ativo}
                    checked={dados.ativo === true ? "checked" : ""}
                />
            </div>
            <div>
                <Stack direction="row" spacing={1}>
                    <Button variant="contained" color="secondary" onClick={() => navigate(caminhoVoltar)}>
                        Voltar
                    </Button>
                    <Button type="submit" variant="contained" color="success">
                        {btnText}
                    </Button>
                </Stack>
            </div>
        </form>
    );
}
export default Form;
