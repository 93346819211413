import { useContext } from "react";
import { Context } from "../../context/UserContext";
import styles from "./Select.module.css";

function Select({ text, name, options, handleOnChange, handleOnBlur, handleOnFocus, value, notOptNull }) {
    //configura o tema
    const { parametro } = useContext(Context);

    return (
        <div className={`${styles.form_control} ${styles[parametro.tema]}`}>
            <label htmlFor={name}>{text}:</label>
            <select
                name={name}
                id={name}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onFocus={handleOnFocus}
                value={value || ""}
            >
                {notOptNull !== true && <option>Selecione um usuário</option>}
                {options.map((opt) => (
                    <option key={opt.id} value={opt.id} title={"CPF: " + opt.CPForCNPJ}>
                        {" "}
                        {opt.pessoaUsuario.usuario}
                    </option>
                ))}
            </select>
        </div>
    );
}

export default Select;
