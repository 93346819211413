import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { useNavigateParams } from "../../../hooks/useNavigateParams";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";

import * as CCL_API from "../../../api/centroCustoLimite";

const Duplicar = () => {
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);

    // Acesse os valores desejados do estado usando o hook useSelector
    const selectedUUIDs = useSelector((state) => state.selectedUUIDs);

    async function managerDuplicar() {
        // let msgType = "success";

        const { data, status } = await CCL_API.Duplicar(selectedUUIDs);
        // .then((response) => {
        //     return response.data;
        // })
        // .catch((error) => {
        //     msgType = "error";
        //     console.error(error);
        //     return error.response.data;
        // });

        setFlashMessage(data.message, status === 200 ? "success" : "error");
        if (status === 200) {
            navigate("/centroCustoLimites", {
                ul: urlParams.get("ul"),
                li: urlParams.get("li"),
                tipo: urlParams.get("tipo"),
            });
        }
    }

    useEffect(() => {
        managerDuplicar(selectedUUIDs);
    }, []);
};
export default Duplicar;
