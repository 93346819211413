import React, { useEffect, useMemo, useState, useContext } from "react";
import { Context } from "../../../context/UserContext";

import moment from "moment";
import "moment-timezone"; // Importe esta linha se estiver usando timezones específicos

import { useNavigateParams } from "../../../hooks/useNavigateParams";
import MaterialReactTable from "material-react-table";

import { ThemeProvider, useTheme } from "@mui/material";
/* tema customizado*/
import tableThemeCustom from "../../form/tableThemeCustom";

//Import Material React Table Translations
import { MRT_Localization_PT_BR } from "material-react-table/locales/pt-BR";
//import CustomDialog from "../../layout/CustomDialog";

import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { Edit } from "@mui/icons-material";
import tratar from "../../../utils/retornaTratamentoURL";
import * as EmpresaAPI from "../../../api/empresa";
import CrudsAPI from "../../../api/CRUDs";
import * as LancamentosAPI from "../../../api/lancamentos";
import * as TabelaAPI from "../../../api/tabela";
import { obterPrimeiroEUltimoDiaDoMes } from "../../../utils/financeiro";

/* util */
import { obterPermissoesUsuario, corrigirDataColumnFilters } from "../../../utils/funcoes";

const Index = () => {
    const [dados, setDados] = useState([]);
    const [token] = useState(localStorage.getItem("token")) || "";
    const [OptionsConta, setConta] = useState([]);
    const [OptionsCentroCustos, setCC] = useState([]);

    const navigate = useNavigateParams();
    const urlParams = new URLSearchParams(window.location.search);

    const ul = urlParams.get("ul") || 3;
    const li = urlParams.get("li") || 36;
    const tipo = urlParams.get("tipo");

    const { parametro } = useContext(Context);
    /* Obtem a paginação padrão */
    useEffect(() => {
        if (parametro && parametro.paginacao) {
            setPagination({
                pageIndex: 0,
                pageSize: parametro.paginacao,
            });
        }
    }, [parametro]);

    //data and fetching state
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    //tratar filtros se vier pela navbar
    if (localStorage.path.replace(/["]/g, "") !== window.location.pathname) {
        tratar.LimparFiltros();
        localStorage.setItem("path", JSON.stringify(window.location.pathname));
    }

    //table state
    const [columnFilterFns, setColumnFiltersMode] = useState(
        localStorage.columnFilterFns.length > 2 ? JSON.parse(localStorage.columnFilterFns) : { dataBaixa: "between" }
    );

    const [columnFilters, setColumnFilters] = useState(
        localStorage.columnFilters.length > 2
            ? JSON.parse(localStorage.columnFilters)
            : [
                  {
                      id: "dataBaixa",
                      value: obterPrimeiroEUltimoDiaDoMes(null, null, "YYYY-MM-DD"),
                  },
              ]
    );

    const [globalFilter, setGlobalFilter] = useState(
        localStorage.globalFilter.length > 2 ? JSON.parse(localStorage.globalFilter) : ""
    );

    const [sorting, setSorting] = useState(localStorage.sorting.length > 2 ? JSON.parse(localStorage.sorting) : []);

    const [pagination, setPagination] = useState({
        pageIndex: JSON.parse(localStorage.pagination).pageIndex || 0,
        pageSize: JSON.parse(localStorage.pagination).pageSize || 100,
    });

    const fetchData = async () => {
        if (!dados.length) {
            setIsLoading(true);
        } else {
            setIsRefetching(true);
        }

        try {
            const { data } = await CrudsAPI.lancamentoConta.listar(
                pagination.pageIndex * pagination.pageSize,
                pagination.pageSize,
                JSON.stringify(columnFilters ?? []),
                JSON.stringify(columnFilterFns ?? []),
                globalFilter ?? "",
                JSON.stringify(sorting ?? [])
            );

            setDados(data.data);
            setRowCount(data.meta.totalRowCount);
        } catch (error) {
            setIsError(true);
            console.error(error);
            return;
        }
        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
    };
    const consultaCC = async () => {
        let result = await EmpresaAPI.centroCustos(dados.empresaId, null, tipo, null, null, null);
        setCC(result);
    };
    const consultaConta = async () => {
        let result = await TabelaAPI.tabela(localStorage.getItem("empresaId"), "Conta", "descricao");
        // Mapear o array para extrair apenas 'id' e 'descricao'
        const mappedResult = result.map((item) => ({
            text: item.descricao,
            value: item.descricao,
        }));

        setConta(mappedResult);
    };

    useEffect(() => {
        if (!token) {
            return navigate("/Auth", { replace: true });
        }

        //funcao para setar localStorage
        localStorage.setItem(
            "pagination",
            JSON.stringify({
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize,
            })
        );
        localStorage.setItem("columnFilterFns", JSON.stringify(columnFilterFns));
        localStorage.setItem("columnFilters", JSON.stringify(columnFilters));
        localStorage.setItem("globalFilter", JSON.stringify(globalFilter));
        localStorage.setItem("sorting", JSON.stringify(sorting));

        consultaConta();
        consultaCC();

        //permissões do usuario
        obterPermissoesUsuario(localStorage.getItem("pessoaId"), localStorage.getItem("empresaId"), ul, li);

        // Formata os filtros de datas apenas se necessário
        const formattedFilters = corrigirDataColumnFilters(columnFilters, null);

        // Verifica se há mudanças nos filtros formatados
        if (JSON.stringify(formattedFilters) !== JSON.stringify(columnFilters)) {
            setColumnFilters(formattedFilters);
        }

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, columnFilterFns, columnFilters, globalFilter, pagination.pageIndex, pagination.pageSize, sorting, ul, li]);

    let titulo = "";
    let conta = false;

    if (!!columnFilters?.length) {
        columnFilters.map((filters) => {
            const { id: columnId, value: filterValue } = filters;
            if (columnId === "Conta.descricao") {
                conta = true;
                titulo = titulo + " da " + filterValue;
            }

            if (columnFilterFns[columnId] === "between") {
                const [start, end] = filterValue;

                if (start || end) {
                    titulo = `Extrado no período de ${moment.utc(start).format("DD/MM/YYYY")} à ${moment
                        .utc(end)
                        .format("DD/MM/YYYY")}`;
                }
            }
        });
    }

    const columns = useMemo(
        () => [
            {
                id: "Extrato",
                header: titulo,
                columns: [
                    {
                        accessorFn: (row) => row.Conta?.descricao || "",
                        id: "Conta.descricao",
                        header: "Conta",
                        filterVariant: "select",
                        filterSelectOptions: OptionsConta,
                    },
                    {
                        //accessorFn: (row) => new Date(row.original.dataBaixa), //convert to Date for sorting and filtering
                        accessorKey: "dataBaixa",
                        header: "Data",
                        // Header: ({ column }) => (
                        //     <em>{column.columnDef.header}</em>
                        // ),
                        //custom header markup
                        // muiFilterTextFieldProps: {
                        //     sx: {
                        //         minWidth: "350px",
                        //     },
                        // },
                        sortingFn: "datetime-local",
                        columnFilterModeOptions: [
                            // "fuzzy",
                            // "equals",
                            "between",
                            // "betweenInclusive",
                            // "greaterThanOrEqualTo",
                            // "lessThanOrEqualTo",
                        ],
                        muiTableBodyCellProps: ({ row }) => ({
                            align: "center",
                            onClick: () => {},
                        }),
                        muiTableHeadCellFilterTextFieldProps: {
                            type: "date",
                            InputLabelProps: {
                                shrink: true,
                            },
                            sx: {
                                minWidth: "200px",
                            },
                        },
                        Cell: ({ renderedCellValue, row }) => {
                            return <span>{moment.utc(row.original.dataBaixa).format("DD/MM/YYYY")}</span>;
                        },
                    },
                    { accessorKey: "natureza", header: "Natureza" },
                    {
                        accessorKey: "valor",
                        header: "Valor",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.valor === null
                                ? ""
                                : parseFloat(row.valor)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "saldoAtual",
                        header: "Saldo",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            !row.saldoAtual
                                ? null
                                : parseFloat(row.saldoAtual)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "historico",
                        header: "Histórico",
                        //you can access a cell in many callback column definition options like this
                        muiTableBodyCellProps: ({ cell }) => ({
                            onClick: () => {},
                        }),
                    },
                ],
            },

            //column definitions...
        ],
        [OptionsConta, titulo] //getCommonEditTextFieldProps
    );

    /* modo tema */
    const theme = tableThemeCustom();

    return (
        <div>
            <ThemeProvider theme={theme}>
                <MaterialReactTable
                    displayColumnDefOptions={{
                        "mrt-row-actions": {
                            muiTableHeadCellProps: {
                                align: "center",
                            },
                            size: 80,
                        },
                        /* "mrt-row-numbers": {
                            enableColumnOrdering: true, //turn on some features that are usually off
                            enableResizing: true,
                            muiTableHeadCellProps: {
                                sx: {
                                    fontSize: "1.2rem",
                                },
                            },
                        },
                        "mrt-row-select": {
                            enableColumnActions: true,
                            enableHiding: true,
                            size: 50,
                        },
                        */
                    }}
                    enableColumnFilterModes
                    enableGlobalFilterModes
                    //enableColumnResizing
                    enableColumnOrdering
                    enableEditing
                    renderTopToolbarCustomActions={({ table }) => {
                        const handleImpimirLancamento = async () => {
                            const urlSearchParams = new URLSearchParams({
                                start: `${pagination.pageIndex * pagination.pageSize}`,
                                size: `${pagination.pageSize}`,
                                filters: JSON.stringify(columnFilters ?? []),
                                filtersMode: JSON.stringify(columnFilterFns ?? []),
                                globalFilter: globalFilter ?? "",
                                sorting: JSON.stringify(sorting ?? []),
                                tipo: urlParams.get("tipo") ?? "",
                            });

                            const response = await LancamentosAPI.extrato(urlSearchParams);

                            // Cria um Blob a partir dos dados do arraybuffer
                            const pdfBlob = new Blob([response.data], {
                                type: "application/pdf",
                            });

                            // Cria um URL temporário para o Blob
                            const pdfUrl = URL.createObjectURL(pdfBlob);

                            // Use o URL do PDF para abrir em uma nova janela ou realizar outras ações
                            window.open(pdfUrl);
                        };
                        return (
                            <div
                                style={{
                                    display: "flex",
                                    gap: "8px",
                                }}
                            >
                                <Tooltip arrow placement="top-end" title={`Imprimir Extrato`}>
                                    <div>
                                        <span>
                                            <Button
                                                color="info"
                                                variant="contained"
                                                disabled={
                                                    JSON.parse(localStorage.getItem("imprimir")) === true && conta ? false : true
                                                }
                                                onClick={handleImpimirLancamento}
                                            >
                                                Imprimir
                                            </Button>
                                        </span>
                                    </div>
                                </Tooltip>
                            </div>
                        );
                    }}
                    renderRowActions={({ row, table }) => (
                        // <Box sx={{ display: "flex", gap: "1rem" }}>
                        <Box
                            sx={{
                                display: "flex",
                                flexWrap: "nowrap",
                                gap: "1px",
                            }}
                        >
                            <Tooltip arrow placement="top-start" title="Editar">
                                <span>
                                    {" "}
                                    <IconButton
                                        onClick={() =>
                                            navigate("/lancamentoContas/CreateOrEdit", {
                                                uuid: row.id,
                                                ul: urlParams.get("ul"),
                                                li: urlParams.get("li"),
                                                tipo: urlParams.get("tipo"),
                                            })
                                        }
                                        disabled={JSON.parse(localStorage.getItem("editar")) === true ? false : true}
                                    >
                                        <Edit />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </Box>
                    )}
                    columns={columns}
                    data={dados}
                    //enableRowSelection
                    getRowId={(row) => row.uuid}
                    manualFiltering
                    manualPagination
                    manualSorting
                    muiToolbarAlertBannerProps={
                        isError
                            ? {
                                  color: "error",
                                  children: "Error loading data",
                              }
                            : undefined
                    }
                    onColumnFiltersChange={setColumnFilters}
                    onColumnFilterFnsChange={setColumnFiltersMode}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    rowCount={rowCount}
                    initialState={{
                        showColumnFilters: true,
                        density: "compact",
                        //columnPinning: { left: ["mrt-row-select"] },
                    }}
                    state={{
                        columnFilterFns,
                        columnFilters,
                        globalFilter,
                        isLoading,
                        pagination,
                        showAlertBanner: isError,
                        showProgressBars: isRefetching,
                        sorting,
                    }}
                    //positionToolbarDropZone={"top"}
                    positionExpandColumn={"first"}
                    positionActionsColumn={"first"}
                    positionToolbarAlertBanner="bottom"
                    localization={MRT_Localization_PT_BR}
                />
            </ThemeProvider>
        </div>
    );
};
export default Index;
