import React, { useEffect, useMemo, useState, useContext } from "react";
import { Context } from "../../../context/UserContext";

import { useDispatch } from "react-redux";
import { selectComponent, clearSelectedComponent } from "../../../store/reducers/actions";

import { useLocation } from "react-router-dom";
import { useNavigateParams } from "../../../hooks/useNavigateParams";
import MaterialReactTable from "material-react-table";
import { ThemeProvider, useTheme } from "@mui/material";
/* tema customizado*/
import tableThemeCustom from "../../form/tableThemeCustom";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";

//Import Material React Table Translations
import { MRT_Localization_PT_BR } from "material-react-table/locales/pt-BR";

import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { MoneyOff } from "@mui/icons-material";

import tratar from "../../../utils/retornaTratamentoURL";
import * as LancamentosBaixaAPI from "../../../api/lancamentoBaixa";

/* util */
import { obterPermissoesUsuario } from "../../../utils/funcoes";

const Index = () => {
    //const [lancamentos, setLancamentos] = useState([]);
    const [lancamentoBaixa, setLancamentoBaixa] = useState([]);
    const [token] = useState(localStorage.getItem("token")) || "";
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();

    const location = useLocation();
    const urlParams = new URLSearchParams(window.location.search);

    const ul = urlParams.get("ul");
    const li = urlParams.get("li");

    const uuid = urlParams.get("uuid");

    const { parametro } = useContext(Context);
    /* Obtem a paginação padrão */
    useEffect(() => {
        if (parametro && parametro.paginacao) {
            setPagination({
                pageIndex: 0,
                pageSize: parametro.paginacao,
            });
        }
    }, [parametro]);

    //baixar lancamentos
    const dispatch = useDispatch();

    //data and fetching state
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    //tratar filtros se vier pela navbar
    if (localStorage.path.replace(/["]/g, "") !== window.location.pathname) {
        tratar.LimparFiltros();
        localStorage.setItem("path", JSON.stringify(window.location.pathname));
    }

    //table state
    const [columnFilterFns, setColumnFiltersMode] = useState(
        localStorage.columnFilterFns.length > 2 ? JSON.parse(localStorage.columnFilterFns) : []
    );

    const [columnFilters, setColumnFilters] = useState(
        localStorage.columnFilters.length > 2 ? JSON.parse(localStorage.columnFilters) : []
    );

    const [globalFilter, setGlobalFilter] = useState(
        localStorage.globalFilter.length > 2 ? JSON.parse(localStorage.globalFilter) : ""
    );

    const [sorting, setSorting] = useState(localStorage.sorting.length > 2 ? JSON.parse(localStorage.globalFilter) : []);

    const [pagination, setPagination] = useState({
        pageIndex: JSON.parse(localStorage.pagination).pageIndex || 0,
        pageSize: JSON.parse(localStorage.pagination).pageSize || 10,
    });

    const fetchData = async () => {
        if (!lancamentoBaixa.length) {
            setIsLoading(true);
        } else {
            setIsRefetching(true);
        }

        const url = new URL(
            "/lancamentoBaixa/listar",
            process.env.NODE_ENV === "production" ? process.env.REACT_APP_API : "http://localhost:3333/"
        );
        url.searchParams.set("start", `${pagination.pageIndex * pagination.pageSize}`);
        url.searchParams.set("size", `${pagination.pageSize}`);
        url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
        url.searchParams.set("filtersMode", JSON.stringify(columnFilterFns ?? []));
        url.searchParams.set("globalFilter", globalFilter ?? "");
        url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
        url.searchParams.set("tipo", urlParams.get("tipo") ?? "");
        url.searchParams.set("uuid", uuid ?? "");

        try {
            LancamentosBaixaAPI.listar(
                pagination.pageIndex * pagination.pageSize,
                pagination.pageSize,
                JSON.stringify(columnFilters ?? []),
                JSON.stringify(columnFilterFns ?? []),
                globalFilter ?? "",
                JSON.stringify(sorting ?? []),
                urlParams.get("tipo") ?? "",
                uuid ?? ""
            ).then((response) => {
                setLancamentoBaixa(response.data.data);
                setRowCount(response.data.meta.totalRowCount);
            });
        } catch (error) {
            setIsError(true);
            console.error(error);
            return;
        }
        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
    };

    //função para filtrar
    function filtrarLancamentosPorUUIDs(lancamentoBaixa, uuids) {
        if (uuids?.length > 0) {
            return lancamentoBaixa.filter((lancamento) => uuids.includes(lancamento.uuid));
        } else {
            return lancamentoBaixa;
        }
    }

    const handleEstornarBaixas = async (row) => {
        let lancamentosFiltrados = {};
        if (row.id) {
            lancamentosFiltrados = filtrarLancamentosPorUUIDs(lancamentoBaixa, row.id);
        } else {
            lancamentosFiltrados = row;
        }

        let msgType = "success";
        if (lancamentosFiltrados) {
            try {
                const response = await LancamentosBaixaAPI.estornar(lancamentosFiltrados);

                setFlashMessage(response.data.message, msgType);

                // Após a exclusão, chame a função fetchData() novamente para atualizar
                fetchData();
            } catch (error) {
                msgType = "error";
                console.error(error);
                setFlashMessage(error.response.data.message, msgType);
            }
        }
    };

    useEffect(() => {
        if (!token) {
            return navigate("/Auth", { replace: true });
        }

        //funcao para setar localStorage
        localStorage.setItem(
            "pagination",
            JSON.stringify({
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize,
            })
        );
        localStorage.setItem("columnFilterFns", JSON.stringify(columnFilterFns));
        localStorage.setItem("columnFilters", JSON.stringify(columnFilters));
        localStorage.setItem("globalFilter", JSON.stringify(globalFilter));
        localStorage.setItem("sorting", JSON.stringify(sorting));

        //permissões do usuario
        obterPermissoesUsuario(localStorage.getItem("pessoaId"), localStorage.getItem("empresaId"), ul, li);

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        token,
        columnFilterFns,
        columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize,
        sorting,
        location.search,
        urlParams,
        ul,
        li,
    ]);

    const columns = useMemo(
        () => [
            {
                id: "lancamentoBaixa",
                header: `Lançamentos Baixados à ${urlParams.get("tipo")}`,
                columns: [
                    {
                        accessorKey: "Lancamento.Pessoa.nomePrincipal",
                        header: "Parceiro",
                    },
                    {
                        accessorKey: "Lancamento.valorOriginal",
                        header: "Valor Total",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.valorOriginal === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.Lancamento.valorOriginal)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "Lancamento.parcelas",
                        header: "Parcela",
                        muiTableBodyCellProps: {
                            align: "center",
                        },
                    },
                    {
                        accessorKey: "Lancamento.valor",
                        header: "Valor Parcela",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.Lancamento.valor === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.Lancamento.valor)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "Lancamento.descontoValor",
                        header: "Desconto",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.Lancamento.descontoValor === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.Lancamento.descontoValor)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "Lancamento.acrescimoValor",
                        header: "Acréscimo",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.Lancamento.acrescimoValor === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.Lancamento.acrescimoValor)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "Lancamento.dataVencimento",
                        header: "Vencimento",
                        columnFilterModeOptions: [
                            "fuzzy",
                            "between",
                            "betweenInclusive",
                            "greaterThan",
                            "greaterThanOrEqualTo",
                            "lessThan",
                            "lessThanOrEqualTo",
                        ],
                        muiTableHeadCellFilterTextFieldProps: {
                            type: "date",
                            InputLabelProps: {
                                shrink: true,
                            },
                        },
                        sortingFn: "datetime-local",
                        muiTableBodyCellProps: ({ row }) => ({
                            align: "center",
                            onClick: () => {},
                        }),
                        Cell: ({ renderedCellValue, row }) => {
                            const dataVencimento = new Date(row.original.Lancamento.dataVencimento);
                            const formattedDate = dataVencimento.toLocaleDateString();
                            return <span>{formattedDate}</span>;
                        },
                    },
                    {
                        accessorKey: "valor",
                        header: "Valor Baixado",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.valor === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.valor)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "dataBaixa",
                        header: "Data Baixa",
                        columnFilterModeOptions: [
                            "fuzzy",
                            "between",
                            "betweenInclusive",
                            "greaterThan",
                            "greaterThanOrEqualTo",
                            "lessThan",
                            "lessThanOrEqualTo",
                        ],
                        muiTableHeadCellFilterTextFieldProps: {
                            type: "date",
                            InputLabelProps: {
                                shrink: true,
                            },
                        },
                        sortingFn: "datetime-local",
                        muiTableBodyCellProps: ({ row }) => ({
                            align: "center",
                            onClick: () => {},
                        }),
                        Cell: ({ renderedCellValue, row }) => {
                            const dataBaixa = new Date(row.original.dataBaixa);
                            const formattedDate = dataBaixa.toLocaleDateString();
                            return <span>{formattedDate}</span>;
                        },
                    },
                    {
                        accessorKey: "documento",
                        header: "Documento",
                    },
                    {
                        accessorKey: "numeroBoleto",
                        header: "Número Boleto",
                    },
                    {
                        accessorKey: "nossoNumero",
                        header: "Nosso Número",
                    },
                    {
                        accessorKey: "Lancamento.Empresa.nomeFantasia",
                        header: "Empresa",
                    },
                ],
            },

            //column definitions...
        ],
        [] //getCommonEditTextFieldProps
    );

    const theme = tableThemeCustom();

    return (
        <div>
            <ThemeProvider theme={theme}>
                <MaterialReactTable
                    enableColumnFilterModes
                    enableGlobalFilterModes
                    //enableColumnResizing
                    enableColumnOrdering
                    enableEditing
                    enableGrouping
                    enablePinning
                    enableRowActions
                    displayColumnDefOptions={{
                        "mrt-row-actions": {
                            muiTableHeadCellProps: {
                                align: "center",
                            },
                            size: 80,
                        },
                        "mrt-row-numbers": {
                            enableColumnOrdering: true, //turn on some features that are usually off
                            enableResizing: true,
                            muiTableHeadCellProps: {
                                sx: {
                                    fontSize: "1.2rem",
                                },
                            },
                        },
                        "mrt-row-select": {
                            enableColumnActions: true,
                            enableHiding: true,
                            size: 50,
                        },
                    }}
                    renderRowActions={({ row, table }) => (
                        <Box
                            sx={{
                                display: "flex",
                                flexWrap: "nowrap",
                                gap: "1px",
                            }}
                        >
                            <Tooltip arrow placement="top-start" title="Estornar Baixa">
                                <span>
                                    <IconButton
                                        color="error"
                                        onClick={() => handleEstornarBaixas(row)}
                                        disabled={
                                            localStorage.getItem("cancelarBaixa") === "true" && row.original.estornado === false
                                                ? false
                                                : true
                                        }
                                    >
                                        <MoneyOff />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </Box>
                    )}
                    renderTopToolbarCustomActions={({ table }) => {
                        const handleEstornarLancamentos = async () => {
                            dispatch(clearSelectedComponent()); // Limpa os valores selecionados
                            const uuids = table.getSelectedRowModel().flatRows.map((row) => row.id);
                            dispatch(selectComponent(uuids)); // Preenche com os novos valores

                            // Filtrar os lançamentos com base nos UUIDs ou manter a lista original
                            const lancamentosFiltrados = filtrarLancamentosPorUUIDs(lancamentoBaixa, uuids);

                            await handleEstornarBaixas(lancamentosFiltrados);
                        };

                        return (
                            <div
                                style={{
                                    display: "flex",
                                    gap: "8px",
                                }}
                            >
                                <Tooltip arrow placement="top-end" title={`Voltar aos Lançamentos à ${urlParams.get("tipo")}`}>
                                    <div>
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            disabled={JSON.parse(localStorage.getItem("incluir")) === true ? false : true}
                                            onClick={() =>
                                                navigate("/Lancamentos", {
                                                    ul: urlParams.get("ul"),
                                                    li: urlParams.get("li"),
                                                    tipo: urlParams.get("li") === "19" ? "Pagar" : "Receber",
                                                })
                                            }
                                        >
                                            Voltar
                                        </Button>
                                    </div>
                                </Tooltip>
                                <Tooltip
                                    arrow
                                    placement="top-end"
                                    title={`Estornar lançamentos à ${urlParams.get("tipo")} selecionados`}
                                >
                                    <div>
                                        <Button
                                            color="warning"
                                            variant="contained"
                                            disabled={
                                                JSON.parse(localStorage.getItem("cancelar")) === false ||
                                                (!table.getIsSomeRowsSelected() && !table.getIsAllPageRowsSelected())
                                            }
                                            onClick={
                                                !table.getIsSomeRowsSelected() && !table.getIsAllPageRowsSelected()
                                                    ? null
                                                    : handleEstornarLancamentos
                                            }
                                        >
                                            Estornar
                                        </Button>
                                    </div>
                                </Tooltip>
                            </div>
                        );
                    }}
                    columns={columns}
                    data={lancamentoBaixa}
                    enableRowSelection
                    getRowId={(row) => row.uuid}
                    manualFiltering
                    manualPagination
                    manualSorting
                    muiToolbarAlertBannerProps={
                        isError
                            ? {
                                  color: "error",
                                  children: "Error loading data",
                              }
                            : undefined
                    }
                    onColumnFiltersChange={setColumnFilters}
                    onColumnFilterFnsChange={setColumnFiltersMode}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    rowCount={rowCount}
                    initialState={{
                        showColumnFilters: true,
                        density: "compact",
                        //columnPinning: { left: ["mrt-row-select"] },
                    }}
                    state={{
                        columnFilterFns,
                        columnFilters,
                        globalFilter,
                        isLoading,
                        pagination,
                        showAlertBanner: isError,
                        showProgressBars: isRefetching,
                        sorting,
                    }}
                    positionToolbarDropZone={"bottom"}
                    positionExpandColumn={"first"}
                    positionActionsColumn={"first"}
                    positionToolbarAlertBanner="bottom"
                    localization={MRT_Localization_PT_BR}
                />
            </ThemeProvider>
        </div>
    );
};
export default Index;
