import React, { useEffect, useState } from "react";
import api from "../../api/.";

import styles from "./Header.module.css";

import Logo from "../../assets/img/Globe-256.png";

/* contexts */
import { Context } from "../../context/UserContext";
import { useContext } from "react";
import packageJson from "../../../package.json";

const appVersion = packageJson.version;

const caminho = process.env.REACT_APP_API;

function Header() {
    const { authenticated, parametro } = useContext(Context);
    const [backendVersion, setBackendVersion] = useState("");

    useEffect(() => {
        const fetchVersion = async () => {
            try {
                const response = await api.get("/api/version"); // Endpoint do seu backend
                setBackendVersion(response.data.version);
            } catch (error) {
                console.error("Erro ao obter a versão do backend", error);
            }
        };

        fetchVersion();
    }, [backendVersion]); // Executa apenas uma vez durante o montagem do componente

    return (
        <header>
            <div className={styles.header_logo}>
                <a href="/" title="Home">
                    <img
                        src={
                            localStorage.getItem("logomarca")
                                ? `${caminho}imagens/empresas/${localStorage.getItem("logomarca").replace(/["]/g, "")}`
                                : Logo
                        }
                        alt="Logomarca da empresa"
                        width={parametro?.logomarcaLargura || 80}
                        height={parametro?.logomarcaAltura || 80}
                    />
                </a>

                <h2>{localStorage.getItem("nomeFantasia") && `${localStorage.getItem("nomeFantasia").replace(/["]/g, "")}`}</h2>
            </div>
            <div className={`User ${authenticated ? "Logado" : "NaoLogado"}`}>
                <p style={{ fontSize: "10px" }}>Cliente: {appVersion}</p>
                <p style={{ fontSize: "10px" }}>Servidor: {backendVersion}</p>
                <h4>Olá: {localStorage.getItem("nome")?.replace(/["]/g, "") || "visitante"} </h4>
            </div>
        </header>
    );
}

export default Header;
