import { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import "react-tabs/style/react-tabs.css";

import moment from "moment";
import "moment/locale/pt-br";

import { Stack, Button, Tooltip } from "@mui/material";
import { Context } from "../../../context/UserContext";

/* hooks */
import { useNavigateParams } from "../../../hooks/useNavigateParams";
/* utils */
import { formatarNumero } from "../../../utils/masks";

import Input from "../../form/Input";
import InputNumber from "../../form/InputNumber";
import Select from "../../form/Select";
import Checkbox from "../../form/Checkbox";
import TextArea from "../../form/TextArea";

/* layout */
import formStyles from "../../form/Form.module.css";

/* funções */
import { retornaData, retornaDataBr } from "../../../utils/retornaDataFormatada";
import * as TabelaAPI from "../../../api/tabela";

function FormBaixarLancamento({ token, handleSubmit, formData, btnText, caminhoVoltar }) {
    const [lancamento, setLancamento] = useState(formData || []);
    const { parametro } = useContext(Context);
    const navigate = useNavigateParams();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);

    const [OptionsConta, setConta] = useState([]);

    const [novoLancamento, setNovoLancamento] = useState({
        dataBaixa: retornaData(moment()),
        tipo: urlParams.get("tipo"),
        valorTotalBaixa: 0,
        agrupar: true,
    });

    const consultaConta = async () => {
        let dados = await TabelaAPI.tabela(
            lancamento.Empresa?.id === undefined ? localStorage.getItem("empresaId") : lancamento.Empresa.id,
            "Conta",
            "descricao"
        );
        setConta(dados);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLancamento(formData);
            await consultaConta(); // Aguarda a conclusão da consultaConta
        };

        fetchData();
    }, [formData]);

    useEffect(() => {
        setNovoLancamento({
            ...novoLancamento,
            valorTotalBaixa: lancamento[0]?.valorTotalParaBaixar,
        });
    }, [lancamento]);

    async function handleChange(e) {
        if (e.target.name === "agrupar") {
            setNovoLancamento((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.checked,
            }));
        } else if (e.target.name === "dataBaixa") {
            setNovoLancamento((prevState) => ({
                ...prevState,
                dataBaixa: e.target.value ?? retornaData(moment()),
            }));
        } else {
            setNovoLancamento((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value,
            }));
        }
    }
    async function handleOnBlur(e) {}
    async function handleOnFocus(e) {}

    const submit = (e) => {
        e.preventDefault();
        if (!novoLancamento.contaId) {
            novoLancamento.contaId = document.getElementById("contaId")?.value;
        }
        handleSubmit(lancamento, novoLancamento);
    };

    return (
        <form onSubmit={submit}>
            <h1>Baixa de Lançamentos à {urlParams.get("tipo")}</h1>
            <div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "1rem",
                    }}
                >
                    <Input
                        text="Data"
                        type="date"
                        name="dataBaixa"
                        placeholder="Data"
                        title="Data da baixa no sistema financeiro"
                        handleOnChange={handleChange}
                        handleOnFocus={handleOnFocus}
                        handleOnBlur={handleOnBlur}
                        value={(novoLancamento.dataBaixa && retornaData(novoLancamento.dataBaixa)) || retornaData(moment())}
                    />
                    <Select
                        name="contaId"
                        title={`Define a conta para ${urlParams.get("tipo")} os lançamentos abaixo`}
                        text="Conta"
                        inputWidth="160px"
                        options={OptionsConta}
                        handleOnChange={handleChange}
                        handleOnFocus={handleOnFocus}
                        handleOnBlur={handleOnBlur}
                        value={novoLancamento.contaId}
                        isEnum={false}
                        notOptNull={true}
                    />
                    <InputNumber
                        text="Total"
                        type="text"
                        name="valorTotalBaixa"
                        placeholder="Valor R$"
                        inputWidth="150px"
                        title="Total de lançamentos para baixar"
                        handleOnChange={handleChange}
                        handleOnFocus={handleOnFocus}
                        handleOnBlur={handleOnBlur}
                        value={novoLancamento.valorTotalBaixa ? formatarNumero(novoLancamento.valorTotalBaixa) : ""}
                        readonly={true}
                    />
                    <Checkbox
                        title="Ao agrupar as baixas, será feito um único lançamento no conta corrente"
                        text="Agrupar"
                        name="agrupar"
                        type="checkbox"
                        handleOnChange={handleChange}
                        inputWidth="38px"
                        inputheight="38px"
                        value={novoLancamento.agrupar}
                        checked={novoLancamento.agrupar === true ? "checked" : ""}
                    />
                </div>
                <div>
                    <TextArea
                        text="Histórico"
                        type="text"
                        name="historico"
                        placeholder="Digite aqui o histórico da baixa"
                        title="Digite o histórico da baixa dos lançamentos listados"
                        handleOnChange={handleChange}
                        handleOnFocus={handleOnFocus}
                        handleOnBlur={handleOnBlur}
                        value={novoLancamento.historico || ""}
                        textAlign="left"
                        linhas="2"
                        colunas="66"
                    />
                </div>
                <div>
                    <table className={`${formStyles.table} ${formStyles[parametro.tema]}`}>
                        <thead>
                            <tr>
                                <th className={formStyles.th} align="left">
                                    Parceiro
                                </th>
                                <th className={formStyles.th} align="center">
                                    Vencimento
                                </th>

                                <th className={formStyles.th} align="rigth">
                                    Título
                                </th>
                                <th className={formStyles.th} align="center">
                                    Parcela
                                </th>
                                <th className={formStyles.th} align="center">
                                    Valor
                                </th>
                                <th className={formStyles.th} align="rigth">
                                    Pago
                                </th>
                                <th className={formStyles.th} align="rigth">
                                    Aberto
                                </th>
                            </tr>
                        </thead>
                        <tbody className={formStyles.tbody}>
                            <>
                                {Object?.keys(lancamento)?.map((key, index, array) => (
                                    <tr className={formStyles.tr} key={lancamento[key].uuid || index}>
                                        <td
                                            className={formStyles.td}
                                            style={{
                                                textAlign: "left",
                                            }}
                                        >
                                            {lancamento[key].Pessoa.nomePrincipal}
                                        </td>
                                        <td
                                            className={formStyles.td}
                                            style={{
                                                textAlign: "center",
                                            }}
                                        >
                                            {retornaDataBr(lancamento[key].dataVencimento)}
                                        </td>

                                        <td
                                            className={formStyles.td}
                                            style={{
                                                textAlign: "right",
                                            }}
                                        >
                                            {formatarNumero(lancamento[key].valorOriginal)}
                                        </td>
                                        <td
                                            className={formStyles.td}
                                            style={{
                                                textAlign: "center",
                                            }}
                                        >
                                            {lancamento[key].parcelas}
                                        </td>
                                        <td
                                            className={formStyles.td}
                                            style={{
                                                textAlign: "right",
                                            }}
                                        >
                                            {formatarNumero(lancamento[key].valor)}
                                        </td>
                                        <td
                                            className={formStyles.td}
                                            style={{
                                                textAlign: "right",
                                            }}
                                        >
                                            {formatarNumero(lancamento[key].valorBaixado || "")}
                                        </td>
                                        <td
                                            className={formStyles.td}
                                            style={{
                                                textAlign: "right",
                                            }}
                                        >
                                            {formatarNumero(lancamento[key].valorAberto)}
                                        </td>
                                    </tr>
                                ))}
                            </>
                        </tbody>
                    </table>
                </div>
            </div>
            <div>
                <Stack direction="row" spacing={1}>
                    <Tooltip arrow placement="top-start" title="Voltar">
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() =>
                                navigate(caminhoVoltar, {
                                    ul: urlParams.get("ul"),
                                    li: urlParams.get("li"),
                                    tipo: urlParams.get("tipo"),
                                })
                            }
                        >
                            Voltar
                        </Button>
                    </Tooltip>
                    <Tooltip arrow placement="top-start" title="Confirma a baixa dos lançamentos">
                        <Button type="submit" variant="contained" color="success">
                            {"Baixar"}
                        </Button>
                    </Tooltip>
                </Stack>
            </div>
        </form>
    );
}
export default FormBaixarLancamento;
