import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Context } from "../../../context/UserContext";
import Form from "./Form";
import styles from "../../form/Form.module.css";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";

import * as EmpresaAPI from "../../../api/empresa";
import * as TabelaAPI from "../../../api/tabela";
import CrudsAPI from "../../../api/CRUDs";

const CreateOrEdit = () => {
    const [dados, setDados] = useState({});
    const [token] = useState(localStorage.getItem("token")) || "";
    const { parametro } = useContext(Context);
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);

    const uuid = urlParams.get("uuid");
    let btnText = "";
    !uuid ? (btnText = "Cadastrar") : (btnText = "Atualizar");

    //enums e tabelas
    const [OptionsConta, setConta] = useState([]);
    const consultaConta = async () => {
        let result = await TabelaAPI.tabela(localStorage.getItem("empresaId"), "Conta", "descricao");
        setConta(result);
    };

    useEffect(() => {
        if (!token) {
            return navigate("/Auth", { replace: true });
        }

        consultaConta();
        if (uuid) {
            CrudsAPI.lancamentoConta
                .consulta(uuid)
                .then((response) => {
                    setDados(response.data);
                })
                .catch((error) => {
                    console.error(error);
                    setFlashMessage(error.response.data.message, error.response.data.type);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, uuid]);

    async function managerDados(dados) {
        let urlNavigate = "";

        try {
            let response;

            if (uuid) {
                response = await CrudsAPI.lancamentoConta.editar(dados);
            } else {
                response = await CrudsAPI.lancamentoConta.cadastrar(dados);
            }

            setFlashMessage(response.data.message, response.data.type);

            setTimeout(() => {
                if (response.data.type === "success") {
                    navigate("/lancamentoContas");
                }
            }, parametro.msgTempo * 1000);
        } catch (error) {
            console.error(error);
            setFlashMessage(error.response.data.message, error.response.data.type);
        }
    }

    return (
        <section className={`${styles.form_container} ${styles[parametro.tema]}`}>
            <Form
                token={token}
                handleSubmit={managerDados}
                formData={dados}
                btnText={btnText}
                OptionsConta={OptionsConta}
                caminhoVoltar={`/lancamentoContas`}
            />
        </section>
    );
};
export default CreateOrEdit;
