import { useState, useEffect } from "react";
import "react-tabs/style/react-tabs.css";
import "moment/locale/pt-br";

import { Stack, Button } from "@mui/material";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";
import { useNavigateParams } from "../../../hooks/useNavigateParams";
import * as TabelaAPI from "../../../api/tabela";

import Input from "../../form/Input";
import InputNumber from "../../form/InputNumber";
import TextArea from "../../form/TextArea";
import Checkbox from "../../form/Checkbox";
import SelectEmpresa from "../../form/SelectEmpresa";
import Select from "../../form/Select";

/* utils */
import { formatarNumero, formatarNumeroDecimal } from "../../../utils/masks";

function Form({ token, handleSubmit, formData, btnText, caminhoVoltar, OptionsEmpresa, OptionsOperacao }) {
    const [dados, setDados] = useState(formData || {});
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();
    const [preview, setPreview] = useState([]);

    if (!dados.ativo) {
        dados.ativo = true;
    }

    useEffect(() => {
        setDados(formData || {});
    }, [formData]);

    function handleChange(e) {
        if (e.target.name === "ativo") {
            setDados({ ...dados, [e.target.name]: e.target.checked });
        } else if (e.target.name === "manutencao" || e.target.name === "rendimento" || e.target.name === "tolerancia") {
            const valorFormatado = formatarNumeroDecimal(e.target.value);
            setDados({
                ...dados,
                [e.target.name]: valorFormatado,
            });
        } else {
            setDados({ ...dados, [e.target.name]: e.target.value });
        }
    }

    async function handleOnBlur(e) {
        //verifica duplicidade
        if (e.target.name === "descricao" && btnText === "Cadastrar") {
            const duplicidade = await TabelaAPI.duplicidadeDescricao(document.getElementById("empresaId").value, "Maquina", e.target.value);

            if (duplicidade?.uuid) {
                setFlashMessage("Registro em duplicidade!", "warning");
                return navigate(`/Maquinas/CreateOrEdit`, {
                    uuid: duplicidade.uuid,
                });
            }
        }
    }
    function handleOnFocus(e) {}

    const submit = (e) => {
        e.preventDefault();
        if (!dados.empresaId) {
            dados.empresaId = parseInt(document.getElementById("empresaId").value);
        }
        if (!dados.operacaoId) {
            dados.operacaoId = parseInt(document.getElementById("operacaoId").value);
        }

        handleSubmit(dados);
    };

    return (
        <form onSubmit={submit}>
            <h1>Máquina</h1>
            <div>
                <SelectEmpresa
                    text="Selecione a Empresa"
                    name="empresaId"
                    options={OptionsEmpresa}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.empresaId || ""}
                    required
                    notOptNull={true}
                />
                <Select
                    text="Selecione a Operação"
                    name="operacaoId"
                    options={OptionsOperacao}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.operacaoId || ""}
                    required
                    notOptNull={true}
                />
                <Input
                    text="Descrição"
                    type="text"
                    name="descricao"
                    placeholder="Nome da máquina"
                    title="Digite o nome da máquina"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.descricao || ""}
                    required={true}
                />
                <InputNumber
                    text="Manutenção"
                    type="text"
                    name="manutencao"
                    placeholder="Comprimento"
                    inputWidth="100px"
                    title="digite o tempo em meses para manutenção preventiva da máquina"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.manutencao ? formatarNumero(dados.manutencao) : ""}
                    required={true}
                />
                <InputNumber
                    text="Rendimento"
                    type="text"
                    name="rendimento"
                    placeholder="Rendimento"
                    inputWidth="100px"
                    title="digite o rendimento esperado da máquina conforme fabricante"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.rendimento ? formatarNumero(dados.rendimento) : ""}
                    required={true}
                />
                <InputNumber
                    text="Tolerância"
                    type="text"
                    name="tolerancia"
                    placeholder="Tolerância"
                    inputWidth="100px"
                    title="digite a tolerância em razão do rendimento da máquina"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.tolerancia ? formatarNumero(dados.tolerancia) : ""}
                    required={true}
                />
                <Checkbox
                    title="Quando marcado a forma de pagamento ou recebimento esta ATIVO"
                    text="Ativo"
                    name="ativo"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.ativo}
                    checked={dados.ativo === true ? "checked" : ""}
                />
            </div>
            <div>
                {" "}
                <TextArea
                    text="Aplicação"
                    type="text"
                    name="aplicacao"
                    placeholder="Digite aqui detalhes da máquina"
                    title="aplicação da operação"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.aplicacao || ""}
                    textAlign="left"
                    linhas="4"
                    colunas="122"
                />
            </div>
            <div>
                <Stack direction="row" spacing={1}>
                    <Button variant="contained" color="secondary" onClick={() => navigate(caminhoVoltar)}>
                        Voltar
                    </Button>
                    <Button type="submit" variant="contained" color="success">
                        {btnText}
                    </Button>
                </Stack>
            </div>
        </form>
    );
}
export default Form;
