import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Context } from "../../../context/UserContext";
import Form from "./Form";
import styles from "../../form/Form.module.css";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";

import * as EmpresaAPI from "../../../api/empresa";
import * as TabelaAPI from "../../../api/tabela";
import * as ProdutoAPI from "../../../api/produto";

import { buildFormData, handleArray } from "../../../utils/FormDataBuilder";

const CreateOrEdit = () => {
    const [dados, setDados] = useState({});
    const [token] = useState(localStorage.getItem("token")) || "";
    const { parametro } = useContext(Context);
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);

    const uuid = urlParams.get("uuid");
    let btnText = "";
    !uuid ? (btnText = "Cadastrar") : (btnText = "Atualizar");

    //para lancamento vazio preenche valores pré definidos
    if (!dados.empresaId) {
        dados.empresaId = localStorage.getItem("empresaId");
        dados.ativo = true;
    }

    //enums, selects e datalist
    const [OptionsEmpresa, setEmpresa] = useState([]);
    const [OptionsUnidade, setUnidade] = useState([]);
    const [OptionsEmbalagem, setEmbalagem] = useState([]);

    //carregar API's Externas
    const consultaEmpresa = async () => {
        let result = await EmpresaAPI.empresa(localStorage.getItem("empresaId"));
        setEmpresa(result);
    };
    const consultaUnidade = async () => {
        let result = await EmpresaAPI.unidade(localStorage.getItem("empresaId"), false, false, false, false);
        setUnidade(result);
    };
    const consultaEmbalagem = async () => {
        let result = await TabelaAPI.tabela(localStorage.getItem("empresaId"), "Embalagem", "descricao");
        setEmbalagem(result);
    };

    useEffect(() => {
        if (!token) {
            return navigate("/Auth", { replace: true });
        }

        if (uuid) {
            ProdutoAPI.consulta(uuid)
                .then((response) => {
                    setDados(response.data);
                })
                .catch((error) => {
                    console.error(error);
                    setFlashMessage(error.response.data.message, error.response.data.type);
                });
        }

        //popula selects
        consultaEmpresa();
        consultaUnidade();
        consultaEmbalagem();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, uuid]);

    async function managerDados(obj) {
        let urlNavigate = "";

        const formData = new FormData();
        buildFormData(obj, formData);

        try {
            let response;

            if (uuid) {
                response = await ProdutoAPI.editar(formData);
            } else {
                response = await ProdutoAPI.cadastrar(formData);

                if (response.status === 202) {
                    setDados(response.data);
                    response.data.message = "Registro em duplicidade!";
                    response.data.type = "warning";
                    urlNavigate = `/Produtos/CreateOrEdit?uuid=${response.data.uuid}`;
                } else {
                    urlNavigate = "/Produtos";
                }
            }

            setFlashMessage(response.data.message, response.data.type);

            setTimeout(() => {
                if (response.data.type === "success") {
                    navigate("/Produtos", {
                        ul: urlParams.get("ul"),
                        li: urlParams.get("li"),
                        tipo: urlParams.get("tipo"),
                    });
                } else {
                    navigate(urlNavigate);
                }
            }, parametro.msgTempo * 1000);
        } catch (error) {
            console.error(error);
            setFlashMessage(error.response.data.message, error.response.data.type);
        }
    }

    return (
        <section className={`${styles.form_container} ${styles[parametro.tema]}`}>
            <Form
                token={token}
                handleSubmit={managerDados}
                formData={dados}
                btnText={btnText}
                OptionsEmpresa={OptionsEmpresa}
                OptionsUnidade={OptionsUnidade}
                OptionsEmbalagem={OptionsEmbalagem}
                caminhoVoltar={`/Produtos`}
            />
        </section>
    );
};
export default CreateOrEdit;
