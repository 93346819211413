import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Stack, Button } from "@mui/material";

/* hooks */

import Input from "../../form/Input";
import Select from "../../form/Select";
import Checkbox from "../../form/Checkbox";

function Form({
    token,
    handleSubmit,
    formData,
    btnText,
    caminhoVoltar,
    OptionsMenu,
    OptionsOperacao,
}) {
    const [dados, setDados] = useState(formData || {});
    const navigate = useNavigate();

    useEffect(() => {
        setDados(formData || {});

        //define o estado inicial do objeto
        if (!dados.ativo) {
            dados.ativo = true;
        }
    }, [formData]);

    async function handleChange(e) {
        if (
            e.target.name === "ativo" ||
            e.target.name === "apenasAdmin" ||
            e.target.name === "comum"
        ) {
            setDados({ ...dados, [e.target.name]: e.target.checked });
        } else {
            setDados({ ...dados, [e.target.name]: e.target.value });
        }
    }

    async function handleOnBlur(e) {}
    async function handleOnFocus(e) {}

    const submit = (e) => {
        e.preventDefault();
        //rotina pera obter o valor mesmo sem o usuario clicar no input
        if (
            !dados.menuId &&
            document.getElementById("menuId").value !== undefined
        ) {
            dados.menuId = parseInt(document.getElementById("menuId").value);
        }
        handleSubmit(dados);
    };

    return (
        <form onSubmit={submit}>
            <h1>Gestão de SubMenus</h1>
            <div>
                <Select
                    text="Selecione o Menu"
                    name="menuId"
                    options={OptionsMenu}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.menuId || ""}
                    isEnum={false}
                    inputWidth={"170px"}
                />
                <Input
                    text="Sub-Menu"
                    type="text"
                    name="descricao"
                    placeholder="nome do Menu"
                    title="Digite o nome do Menu"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.descricao || ""}
                    required
                />
            </div>
            <div>
                <Input
                    text="Título"
                    type="text"
                    name="title"
                    placeholder="Título"
                    title="Digite o título que aparecerá quando o mouse estiver no submenu"
                    inputWidth="350px"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.title || ""}
                />
            </div>
            <div>
                <Input
                    text="URL"
                    type="text"
                    name="url"
                    placeholder="URL"
                    title="Digite a URL"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.url || ""}
                />
                <Input
                    text="Target"
                    type="text"
                    name="target"
                    placeholder="target"
                    title="Digite o target, _self, _blank, _parent, _top"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.target || ""}
                />
            </div>
            <div>
                <Input
                    text="Tipo"
                    type="text"
                    name="tipo"
                    placeholder="Tipo"
                    title="Digite o tipo Pagar, Receber ou outro conforme uso no sistema"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.tipo || ""}
                />
                <Select
                    text="Operação"
                    name="operacaoId"
                    options={OptionsOperacao}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.operacaoId || ""}
                    isEnum={false}
                    notOptNull={false}
                    required={false}
                    inputWidth={"170px"}
                />
            </div>
            <div>
                <Checkbox
                    title="Quando marcado indica que o menu é apenas para administrador"
                    text="Admin"
                    name="apenasAdmin"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.apenasAdmin}
                    checked={dados.apenasAdmin === true ? "checked" : ""}
                />
                <Checkbox
                    title="Quando marcado indica que o menu é de tabela comum"
                    text="Comun"
                    name="comum"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.comum}
                    checked={dados.comum === true ? "checked" : ""}
                />
                <Checkbox
                    title="Quando marcado o submenu esta ATIVO"
                    text="Ativo"
                    name="ativo"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.ativo}
                    checked={dados.ativo === true ? "checked" : ""}
                />
            </div>
            <div>
                <Stack direction="row" spacing={1}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => navigate(caminhoVoltar)}
                    >
                        Voltar
                    </Button>
                    <Button type="submit" variant="contained" color="success">
                        {btnText}
                    </Button>
                </Stack>
            </div>
        </form>
    );
}

export default Form;
