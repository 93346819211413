import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Context } from "../../../context/UserContext";
import Form from "./Form";
import styles from "../../form/Form.module.css";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";

//utils
import * as TabelaAPI from "../../../api/tabela";
import * as VerificadoresAPI from "../../../api/verificadores";
import * as EmpresaAPI from "../../../api/empresa";
import * as FinanceiroAPI from "../../../api/financeiro";
import * as PessoaAPI from "../../../api/pessoa";

import { buildFormData, handleArray } from "../../../utils/FormDataBuilder";

import mask from "../../../utils/maskCPForCNPJ";

const onlyNumbers = (str) => str.replace(/[^0-9]/g, "");

const CreateOrEdit = () => {
    const [dados, setDados] = useState({});
    const [token] = useState(localStorage.getItem("token")) || "";
    const { parametro } = useContext(Context);
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);

    const uuid = urlParams.get("uuid");

    let btnText = "";
    !uuid ? (btnText = "Cadastrar") : (btnText = "Atualizar");

    //define o estado inicial do objeto
    if (!dados.empresaId) {
        dados.empresaId = localStorage.getItem("empresaId");
        dados.ativo = true;
    }

    //enums e tabelas
    //pessoa
    const [OptionsEmpresa, setEmpresa] = useState([]);
    const [EnumTipo, setEnumTipo] = useState([]);
    const [EnumVinculoFinanceiro, setEnumVinculoFinanceiro] = useState([]);
    const [OptionsFormaFornecedor, setFormaFornecedor] = useState([]);
    const [OptionsConta, setConta] = useState([]);
    const [OptionsFormaCliente, setFormaCliente] = useState([]);

    //pessoaNome
    const [EnumOrigem, setEnumOrigem] = useState([]);

    //pessoaFisica
    const [EnumSexo, setEnumSexo] = useState([]);
    const [EnumEstadoCivil, setEnumEstadoCivil] = useState([]);
    const [EnumTipoSanguineo, setEnumTipoSanguineo] = useState([]);
    const [EnumCnhCategoria, setEnumCnhCategoria] = useState([]);

    //pessoaJuridica
    const [EnumRamoAtividade, setEnumRamoAtividade] = useState([]);
    const [EnumPJTipo, setEnumPJTipo] = useState([]);

    //endereços
    const [ufs, setUfs] = useState([]);
    const [municipios, setMUNICIPIO] = useState([]);
    const [EnumTipoEndereco, setEnumTipoEndereco] = useState([]);

    //contatos
    const [EnumTipoEmail, setEnumTipoEmail] = useState([]);
    const [EnumTipoTelefone, setEnumTipoTelefone] = useState([]);

    const [pessoaEmpresa, setDadosEmpresa] = useState([]);

    //carregar ENUMS
    const consultaEnum = async (table, field, filter) => {
        const result = await TabelaAPI.enums(table, field, filter);
        if (table === "Pessoas") {
            field === "tipo" && setEnumTipo(result ? result : []);
            field === "vinculoFinanceiro" && setEnumVinculoFinanceiro(result ? result : []);
        } else if (table === "pessoaNomes") {
            field === "origem" && setEnumOrigem(result ? result : []);
        } else if (table === "pessoaFisicas") {
            field === "sexo" && setEnumSexo(result ? result : []);
            field === "estadoCivil" && setEnumEstadoCivil(result ? result : []);
            field === "tipoSanguineo" && setEnumTipoSanguineo(result ? result : []);
            field === "cnhCategoria" && setEnumCnhCategoria(result ? result : []);
        } else if (table === "pessoaJuridicas") {
            field === "ramoAtividade" && setEnumRamoAtividade(result ? result : []);
            field === "tipo" && setEnumPJTipo(result ? result : []);
        } else if (table === "pessoaEnderecos") {
            field === "tipo" && setEnumTipoEndereco(result ? result : []);
        } else if (table === "pessoaEmails") {
            field === "tipo" && setEnumTipoEmail(result ? result : []);
        } else if (table === "pessoaTelefones") {
            field === "tipo" && setEnumTipoTelefone(result ? result : []);
        }
    };

    //carregar API's Externas
    const consultaEmpresa = async () => {
        let result = await EmpresaAPI.empresa(dados.empresaId);
        setEmpresa(result);
    };
    const consultaForma = async (tipo) => {
        let result = await FinanceiroAPI.forma(tipo, "", dados.empresaId);

        if (tipo === "Pagar") {
            setFormaFornecedor(result);
        } else if (tipo === "Receber") {
            setFormaCliente(result);
        } else {
            //setForma(result); nesse caso não vou precisar de todos
        }
    };
    const consultaUF = async () => {
        let uf = await VerificadoresAPI.uf("");
        setUfs(uf);
    };
    const consultaMUNICIPIO = async (ufId, uf, descricao) => {
        let municipio = await VerificadoresAPI.municipio(ufId, uf, descricao);
        setMUNICIPIO(municipio);
    };
    const consultaConta = async () => {
        let result = await TabelaAPI.tabela(dados.empresaId, "Conta", "descricao");
        setConta(result);
    };

    //funções e rotinas acionadas pelo useEffect
    useEffect(() => {
        if (!token) {
            return navigate("/Auth", { replace: true });
        }

        if (uuid) {
            PessoaAPI.consulta(uuid)
                .then((response) => {
                    setDados(response.data);
                })
                .catch((error) => {
                    console.error(error);
                    setFlashMessage(error.response.data.message, error.response.data.type);
                });
        }

        //carregar ENUMS
        //pessoa
        consultaEnum("Pessoas", "tipo");
        consultaEnum("Pessoas", "vinculoFinanceiro");
        //pessoaNome
        consultaEnum("pessoaNomes", "origem");
        //pessoaFisica
        consultaEnum("pessoaFisicas", "sexo");
        consultaEnum("pessoaFisicas", "estadoCivil");
        consultaEnum("pessoaFisicas", "tipoSanguineo");
        consultaEnum("pessoaFisicas", "cnhCategoria");
        //pessoaJuridica
        consultaEnum("pessoaJuridicas", "ramoAtividade");
        consultaEnum("pessoaJuridicas", "tipo");
        //Endereco
        consultaEnum("pessoaEnderecos", "tipo");
        //email
        consultaEnum("pessoaEmails", "tipo");
        //telefones
        consultaEnum("pessoaTelefones", "tipo");

        //carregar API's Externas
        consultaEmpresa();
        consultaForma("Pagar");
        consultaForma("Receber");
        consultaUF();
        consultaMUNICIPIO();
        consultaConta();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, uuid]);

    async function managerDados(obj) {
        let urlNavigate = "";

        if (!obj.CPForCNPJ.length) {
            obj.CPForCNPJ = onlyNumbers(obj.CPForCNPJ);
        }

        const formData = new FormData();
        buildFormData(obj, formData);

        try {
            let response;

            if (uuid) {
                response = await PessoaAPI.editar(formData);
            } else {
                response = await PessoaAPI.cadastrar(formData);

                if (response.status === 202) {
                    setDados(response.data);
                    response.data.message = "Registro em duplicidade!";
                    response.data.type = "warning";
                    urlNavigate = `/Pessoa/CreateOrEdit?uuid=${response.data.uuid}`;
                } else {
                    urlNavigate = "/Pessoa";
                }
            }

            setFlashMessage(response.data.message, response.data.type);

            setTimeout(() => {
                if (response.data.type === "success") {
                    navigate("/Pessoa", {
                        ul: urlParams.get("ul"),
                        li: urlParams.get("li"),
                        tipo: urlParams.get("tipo"),
                    });
                } else {
                    navigate(urlNavigate);
                }
            }, parametro.msgTempo * 1000);
        } catch (error) {
            console.error(error);
            setFlashMessage(error.message, "error");
            //setFlashMessage(error.response.data.message, error.response.data.type);
        }
    }

    return (
        <section className={`${styles.form_container} ${styles[parametro.tema]}`}>
            <Form
                token={token}
                handleSubmit={managerDados}
                formData={dados}
                btnText={btnText}
                EnumTipo={EnumTipo}
                EnumVinculoFinanceiro={EnumVinculoFinanceiro}
                EnumOrigem={EnumOrigem}
                EnumSexo={EnumSexo}
                EnumTipoSanguineo={EnumTipoSanguineo}
                EnumEstadoCivil={EnumEstadoCivil}
                EnumCnhCategoria={EnumCnhCategoria}
                EnumRamoAtividade={EnumRamoAtividade}
                EnumPJTipo={EnumPJTipo}
                EnumTipoEndereco={EnumTipoEndereco}
                EnumTipoEmail={EnumTipoEmail}
                EnumTipoTelefone={EnumTipoTelefone}
                ufs={ufs}
                municipios={municipios}
                OptionsEmpresa={OptionsEmpresa}
                OptionsFormaFornecedor={OptionsFormaFornecedor}
                OptionsFormaCliente={OptionsFormaCliente}
                OptionsConta={OptionsConta}
                caminhoVoltar={`/Pessoa`}
            />
        </section>
    );
};
export default CreateOrEdit;
