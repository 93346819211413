import api from ".";

export const enums = async (table, field, filter) => {
    try {
        const { data } = await api.get("/serviceEnums", {
            params: { table, field, filter },
        });
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};

export const menu = async (menu) => {
    try {
        const { data } = await api.get("/serviceMenu", { params: { menu } });
        return data;
    } catch (error) {
        return error;
    }
};

export const menuSub = async (menuId, descricao) => {
    try {
        const { data } = await api.get("/serviceMenuSub", {
            params: { menuId, descricao },
        });
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};

export const tabelaById = async (empresaId, tabela, id) => {
    try {
        const { data } = await api.get("/serviceTabelaById", {
            params: { empresaId, tabela, id },
        });
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};

export const tabela = async (empresaId, tabela, order) => {
    try {
        const { data } = await api.get("/serviceTabela", {
            params: { empresaId, tabela, order },
        });
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};

export const embalagemByProdutoId = async (empresaId, produtoId) => {
    try {
        const { data } = await api.get("/serviceEmbalagemByProdutoId", {
            params: { empresaId, produtoId },
        });
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};

export const fatorByEmbalagemId = async (empresaId, embalagemId) => {
    try {
        const { data } = await api.get("/serviceFatorByEmbalagemId", {
            params: { empresaId, embalagemId },
        });
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};

export const precoByPessoaId = async (empresaId, pessoaId) => {
    try {
        const { data } = await api.get("/serviceTabelaPrecoByPessoaId", {
            params: { empresaId, pessoaId },
        });
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};

export const pessoaTabelaPrecos = async (novaTabelaPreco) => {
    try {
        const { data } = await api.post("/pessoa/tabelaPreco", novaTabelaPreco);
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};

export const campoTabelaById = async (empresaId, campo, tabela, id) => {
    try {
        const { data } = await api.get("/serviceCampoTabelaById", {
            params: { empresaId, campo, tabela, id },
        });
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};
/**
 * Muito utilizado para retornar situacao
 * @param {*} empresaId
 * @param {*} tabela
 * @param {*} descricao
 * @returns
 */
export const TabelaByDescricao = async (empresaId, tabela, descricao) => {
    try {
        const { data } = await api.get("/serviceTabelaByDescricao", {
            params: { empresaId, tabela, descricao },
        });
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};
//duplicidades
export const duplicidadeDescricao = async (empresaId, tabela, descricao) => {
    try {
        const { data } = await api.get("/serviceDuplicidadeDescricao", {
            params: { empresaId, tabela, descricao },
        });
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};

export const duplicidadeCentroCustoLimite = async (empresaId, centroCustoId, mes) => {
    try {
        const { data } = await api.get("/centroCustoLimite/duplicidade/", {
            params: { empresaId, centroCustoId, mes },
        });
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};

export const duplicidadeProdutoModelo = async (empresaId, fabricanteId, descricao) => {
    try {
        const { data } = await api.get("/produtoModelo/duplicidade/", {
            params: { empresaId, fabricanteId, descricao },
        });
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};
