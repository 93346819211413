import api from ".";

export const consulta = (uuid) => api.get(`/lancamento/editar/${uuid}`);

export const editar = (lancamento) =>
    api.post("/lancamento/editar", lancamento);

export const paraBaixar = (selectedUUIDs) =>
    api.post("/lancamento/lancamentosParaBaixar", selectedUUIDs);

export const baixas = (dados) => api.post("/lancamento/baixas", dados);

export const baixar = (lancamento) =>
    api.post("/lancamento/baixar", lancamento);

export const cadastrar = (lancamento) =>
    api.post("/lancamento/cadastrar", lancamento);

export const deletar = (lancamento) =>
    api.post("/lancamento/deletar", lancamento);

export const listar = (
    start,
    size,
    filters,
    filtersMode,
    globalFilter,
    sorting,
    tipo
) =>
    api.get(`/lancamento/listar/`, {
        params: {
            start,
            size,
            filters,
            filtersMode,
            globalFilter,
            sorting,
            tipo,
        },
    });

export const relatorio = (url, pessoasFiltrados) =>
    api.post(`/lancamento/relatorio/?${url.toString()}`, pessoasFiltrados, {
        responseType: "arraybuffer",
    });

export const recibos = (url, pessoasFiltrados) =>
    api.post(`/lancamento/recibos/?${url.toString()}`, pessoasFiltrados, {
        responseType: "arraybuffer",
    });

export const extrato = (url) =>
    api.post(
        `/lancamentoConta/extrato/?${url.toString()}`,
        {},
        {
            responseType: "arraybuffer",
        }
    );
