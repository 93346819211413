import api from "./";

export const retornaEmbalagem = async (embalagemId) => {
    try {
        const { data } = await api.get("/embalagem/retornaEmbalagemById", { params: { embalagemId } });
        return data;
    } catch (error) {
        return error;
    }
};
