import { useState, useEffect, useContext, useMemo } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

/* contexts */
import { Context } from "../../../context/UserContext";

import moment from "moment";
import "moment/locale/pt-br";

import { Stack, Button, Box, Tooltip, IconButton } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import MaterialReactTable from "material-react-table";

import { ThemeProvider, useTheme } from "@mui/material";
/* tema customizado*/
import tableThemeCustom from "../../form/tableThemeCustom";
import tableTheme from "../../form/tableTheme";

//Import Material React Table Translations
import { MRT_Localization_PT_BR } from "material-react-table/locales/pt-BR";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

/* utils */
import { formatarNumero, formatarNumeroDecimal } from "../../../utils/masks";
import { retornaDataHora, retornaData } from "../../../utils/retornaDataFormatada";
import maskCEP from "../../../utils/maskCEP";

import Input from "../../form/Input";
import InputNumber from "../../form/InputNumber";
import Select from "../../form/Select";
import Checkbox from "../../form/Checkbox";
import DataList from "../../form/DataList";
import SelectEmpresa from "../../form/SelectEmpresa";
import TextArea from "../../form/TextArea";

import formStyles from "../../form/Form.module.css";

/* apis */
import * as DataListAPI from "../../../api/dataList";
import * as ProdutoAPI from "../../../api/produto";
import * as TabelaAPI from "../../../api/tabela";
import * as VerificadoresAPI from "../../../api/verificadores";

/* util */
import { obterPermissoesUsuario } from "../../../utils/funcoes";

function Form({ token, handleSubmit, formData, btnText, caminhoVoltar, OptionsEmpresa, OptionsEmbalagem }) {
    const onlyNumbers = (str) => str.replace(/[^0-9]/g, "");

    const [dados, setDados] = useState({});
    const [itensInventario, setItensInventario] = useState([]); //itensInventario da grid

    const [preview, setPreview] = useState([]);
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();
    const { parametro } = useContext(Context);

    const urlParams = new URLSearchParams(window.location.search);

    const ul = urlParams.get("ul") || 2;
    const li = urlParams.get("li") || 32;

    /* Empresa usa tabela comum ou propria */
    let empresaId = 1;
    if (parametro.tabelasComuns === false) {
        empresaId = localStorage.getItem("empresaId");
    }

    //set datalist
    const [NCM, setNCM] = useState(formData?.NCM);
    const [OptionsNCM, setOptionsNCM] = useState([]);
    const [Fabricante, setFabricante] = useState(formData?.Fabricante || {});
    const [produtoBens, setBem] = useState(formData?.produtoBens || {});
    const [produtoBensInventario, setProdutoBensInventario] = useState(formData?.produtoBens?.produtoBensInventario || {});
    const [produtoModelo, setProdutoModelo] = useState({});

    const [Proprietario, setProprietario] = useState(formData?.Proprietario);
    const [OptionsProprietario, setOptionsProprietario] = useState([]);

    const [produtoImagems, setProdutoImagems] = useState([]);

    const [OptionsFabricante, setOptionsFabricante] = useState([]);
    const [OptionsModelo, setOptionsModelo] = useState([]);
    const [OptionsCores, setOptionsCores] = useState([]);
    const [optionsAmbiente, setOptionsAmbiente] = useState([]);

    const [produtoEmbalagens, setEmbalagem] = useState([]);

    const [divImovel, setDivImovel] = useState(true);
    const [divMobilia, setDivMobilia] = useState(false);
    const [divMovel, setDivMovel] = useState(false);

    //abas
    const [tabEnabled, setTabEnabled] = useState({
        produto: true,
        industria: true,
        embalagens: true,
        caracteristicas: true,
        bens: true,
        imagens: true,
    });

    //carrega informações para cadastro inicial
    if (!dados.empresaId) {
        dados.empresaId = localStorage.getItem("empresaId");
    }
    if (!dados.tipo) {
        dados.tipo = "Imobilizado";
    }

    //set enums
    const [EnumTipo, setTipo] = useState(formData?.OptionsTipo || {});
    const [EnumBemTipo, setBemTipo] = useState(formData?.OptionsBemTipo || {});

    const [optionsUF, setUF] = useState([]);
    const [optionsMunicipios, setMunicipio] = useState([]);

    //carregar ENUMS
    const consultaEnum = async (table, field, filter) => {
        const result = await TabelaAPI.enums(table, field, filter);
        if (table === "Produtos") {
            field === "tipo" && setTipo(result ? result : []);
        } else if (table === "produtoBens") {
            field === "tipo" && setBemTipo(result ? result : []);
        }
    };

    //carrega APIs
    const consultaUF = async () => {
        let uf = await VerificadoresAPI.uf("");
        setUF(uf);
    };
    const consultaMUNICIPIO = async () => {
        let municipio = await VerificadoresAPI.municipio(produtoBens.ufId, produtoBens.uf, null);
        setMunicipio(municipio);
    };
    const consultaCEP = async (cep) => {
        const result = await VerificadoresAPI.cep(cep);

        if (result.type === "error") {
            setFlashMessage(result.message, result.type);
        } else {
            setBem({
                ...produtoBens,
                ufId: result.ufId,
                municipioId: result.municipioId,
                bairro: result.neighborhood,
                logradouro: result.street,
            });
        }
        consultaMUNICIPIO(result.ufId);
    };
    const consultaTabela = async (empresaId, tabela, order) => {
        const result = await TabelaAPI.tabela(empresaId, tabela, order);
        if (tabela === "Cor") {
            setOptionsCores(result);
        } else if (tabela === "Ambiente") {
            setOptionsAmbiente(result);
        }
    };

    //Atualiza o Datalist
    const consultaDatalist = async (token, empresaId, tabela, id, descricao, condicao, filtro) => {
        let result = await DataListAPI.dataList(empresaId, tabela, id, descricao, condicao, filtro);
        return result;
    };

    const consultaDatalistProprietario = async (empresaId, filtro, locador, locatario, fiador, funcionarioId) => {
        const result = await DataListAPI.dataListParceiroImovel(empresaId, filtro, locador, locatario, fiador, funcionarioId);
        return result;
    };

    //rotina para criar objetos de tabelas filhas
    const [novaEmbalagem, setNovaEmbalagem] = useState({
        produtoId: dados.id,
        empresaId: dados.empresaId,
        empresa: "",
        embalagemId: "",
        embalagem: "",
        uuid: "",
        codigoBarras: "",
        ativo: true,
        funcao: "adicionar",
    });

    const [novaMobilia, setNovaMobilia] = useState({
        produtoBensId: produtoBens.id,
        ambienteId: produtoBensInventario.ambienteId,
        descricao: "",
        valor: "",

        uuid: "",
        Ambiente: "",
        ativo: true,
        funcao: "adicionar",
    });
    useEffect(() => {
        setNovaEmbalagem({ ...novaEmbalagem, produtoId: dados.id });
    }, [dados.id]);

    useEffect(() => {
        setDados(formData || {});
        setFabricante(formData?.Fabricante || {});
        setEmbalagem(formData?.produtoEmbalagens || []);
        setItensInventario(formData?.produtoBens?.produtoBensInventarios || []);
        setNCM(formData.NCM || {});

        setBem(formData?.produtoBens || {});
        setProdutoModelo(formData?.produtoBens?.produtoModelo || {});
        setProdutoImagems(formData?.produtoImagems || []);
        setTipo(formData?.OptionsTipo);
        setBemTipo(formData?.OptionsBemTipo);
        setProprietario(formData?.produtoBens?.Proprietario);

        setTabEnabled({
            produto: true,
            industria: true,
            embalagens: true,
            caracteristicas: true,
            bens: dados.tipo === "Imobilizado" || dados.tipo === "Material" ? true : false,
            imagens: true,
        });

        //enums
        consultaEnum("Produtos", "tipo");
        consultaEnum("produtoBens", "tipo");

        //carregar API's Externas
        consultaUF();
        consultaMUNICIPIO();

        //select
        consultaTabela(dados.empresaId, "Cor", "descricao");
        consultaTabela(parametro.tabelasComuns === true ? 1 : dados.empresaId, "Ambiente", "descricao");

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    //atualiza tabelas filhas
    useEffect(() => {
        if (produtoBens.tipo === "Móvel") {
            setDivMovel(true);
            setDivImovel(false);
        } else {
            setDivMovel(false);
            setDivImovel(true);
        }

        if (produtoBens.mobiliado === true) {
            setDivMobilia(true);
        } else {
            setDivMobilia(false);
        }
        setDados((prev) => {
            return {
                ...prev,
                produtoBens: produtoBens,
                produtoEmbalagens: produtoEmbalagens,
            };
        });
    }, [produtoBens, produtoEmbalagens]);

    const handleEmbalagem = async () => {
        /* grava no banco de dados o sistema entende que esta no modo EDIT */
        if (!novaEmbalagem.produtoId) {
            novaEmbalagem.produtoId = parseInt(dados.id);
        }
        if (!novaEmbalagem.embalagemId) {
            novaEmbalagem.embalagemId = parseInt(document.getElementById("novaEmbalagem.embalagemId").value);
        }
        if (!novaEmbalagem.empresaId) {
            novaEmbalagem.empresaId = parseInt(document.getElementById("novaEmbalagem.empresaId").value);
        }

        if (dados.id) {
            const result = await ProdutoAPI.embalagem(novaEmbalagem);

            if (result.status === 202) {
                const mensagem = `Embalagem já cadastrada!`;
                setFlashMessage(mensagem, "info");
            } else {
                setFlashMessage(result.message, result.type);

                if (result.type === "success") {
                    setEmbalagem(result.resultado);
                }
                setNovaEmbalagem({
                    produtoId: dados.id,
                    empresaId: dados.empresaId || localStorage.getItem("empresaId"),
                    empresa: "",
                    embalagemId: "",
                    embalagem: "",
                    uuid: "",
                    codigoBarras: "",
                    ativo: false,
                    funcao: "adicionar",
                });
            }
        } else {
            if (novaEmbalagem.funcao === "editar") {
                produtoEmbalagens[novaEmbalagem.index] = {
                    ...produtoEmbalagens[novaEmbalagem.index],
                    ...novaEmbalagem,
                };
            } else if (novaEmbalagem.funcao === "deletar") {
                produtoEmbalagens.splice(novaEmbalagem.index, 1);
            } else {
                setEmbalagem([...produtoEmbalagens, novaEmbalagem]);
                setDados({ ...dados, produtoEmbalagens: produtoEmbalagens });
            }
            setNovaEmbalagem({
                produtoId: dados.id,
                empresaId: dados.empresaId,
                empresa: "",
                embalagemId: "",
                embalagem: "",
                uuid: "",
                codigoBarras: "",
                ativo: false,
                funcao: "adicionar",
            });
        }
    };
    const handleMobilia = async () => {
        /* grava no banco de dados o sistema entende que esta no modo EDIT */
        if (!novaMobilia.produtoBensId) {
            novaMobilia.produtoBensId = parseInt(produtoBens.id);
        }
        if (!novaMobilia.ambienteId) {
            novaMobilia.ambienteId = parseInt(document.getElementById("novaMobilia.ambienteId").value);
        }

        if (produtoBens.id) {
            const result = await ProdutoAPI.mobilia(novaMobilia);

            if (result.status === 202) {
                const mensagem = `Mobília já cadastrada!`;
                setFlashMessage(mensagem, "info");
            } else {
                setFlashMessage(result.message, result.type);

                if (result.type === "success") {
                    setNovaMobilia(result.resultado);
                }
                setNovaMobilia({
                    produtoBensId: novaMobilia.produtoBensId,
                    ambienteId: novaMobilia.ambienteId,
                    descricao: "",
                    valor: "",

                    uuid: "",
                    ativo: true,
                    funcao: "adicionar",
                });
            }

            fetchData("inventario");
        } else {
            if (setNovaMobilia.funcao === "editar") {
                produtoBensInventario[novaMobilia.index] = {
                    ...produtoBensInventario[novaMobilia.index],
                    ...novaMobilia,
                };
            } else if (novaMobilia.funcao === "deletar") {
                produtoBensInventario.splice(novaMobilia.index, 1);
            } else {
                setNovaMobilia([...produtoBensInventario, novaMobilia]);
                setDados({ ...dados, produtoBensInventario: produtoBensInventario });
            }
            setNovaMobilia({
                produtoBensId: produtoBens.id,
                ambienteId: produtoBensInventario.ambienteId,
                descricao: "",
                valor: "",

                uuid: "",
                codigoBarras: "",
                ativo: false,
                funcao: "adicionar",
            });
        }
    };

    async function onFileChange(e) {
        setPreview(Array.from(e.target.files));
        setDados({ ...dados, images: [...e.target.files] });
    }
    async function handleChange(e) {
        const camposMonetarios = [
            "comissao",
            "pesoBruto",
            "pesoLiquido",
            "estoqueMinimo",
            "producaoMinima",
            "pesoMolecular",
            "toleranciaMinima",
            "toleranciaMaxima",
        ];
        const camposBooleanos = [
            "ativo",
            "compra",
            "venda",
            "locavel",
            "embalagem",
            "laminado",
            "sanfonado",
            "separacao",
            "refugo",
            "lavagem",
            "producao",
            "extrusao",
            "finalizaExtrusora",
            "recuperadora",
            "corrugadora",
            "impressora",
            "corteSolda",
        ];
        if (e.target.name.startsWith("produtoModelo.")) {
            setProdutoModelo({
                ...produtoModelo,
                [e.target.name.split(".")[1]]: e.target.value,
            });

            if (e.target.value?.length > 2) {
                let result = await consultaDatalist(
                    token,
                    dados.empresaId,
                    "produtoModelo",
                    "id",
                    "descricao",
                    dados.fabricanteId,
                    e.target.value
                );
                setOptionsModelo(result);
            }

            setBem({
                ...produtoBens,
                modeloId: document.querySelector(`datalist option[value="${e.target.value}"]`)?.dataset.id,
            });
        } else if (e.target.name.startsWith("Proprietario.")) {
            setProprietario({
                ...Proprietario,
                [e.target.name.split(".")[1]]: e.target.value,
            });
            if (e.target.name === "Proprietario.descricao") {
                //carrega o datalist
                if (e.target.value?.length > 0) {
                    const result = await consultaDatalistProprietario(dados.empresaId, e.target.value, true, false, false, null);
                    setOptionsProprietario(result);
                }
                const proprietarioId = document.querySelector(`datalist option[value="${e.target.value}"]`)?.dataset.id;

                if (proprietarioId) {
                    setBem((prev) => ({ ...prev, proprietarioId: proprietarioId }));
                }
            }
        } else if (e.target.name === "tipo") {
            setDados({ ...dados, [e.target.name]: e.target.value });

            if (e.target.value === "Imobilizado" || e.target.value === "Material") {
                setTabEnabled({
                    ...tabEnabled,
                    bens: true,
                });
            } else {
                setTabEnabled({
                    ...tabEnabled,
                    bens: false,
                });
            }
        } else if (camposBooleanos.includes(e.target.name)) {
            setDados({ ...dados, [e.target.name]: e.target.checked });
        } else if (e.target.name.startsWith("NCM")) {
            setNCM({
                ...NCM,
                [e.target.name.split(".")[1]]: e.target.value,
            });
            if (e.target.name.startsWith("NCM.descricao")) {
                //carrega o datalist
                if (e.target.value?.length > 3) {
                    let result = await consultaDatalist(token, empresaId, "NCM", "id", "descricao", "", e.target.value);
                    setOptionsNCM(result);
                }

                setDados({
                    ...dados,
                    ncmId: document.querySelector(`datalist option[value="${e.target.value}"]`)?.dataset.id,
                });

                if (document.querySelector(`datalist option[value="${e.target.value}"]`)?.value) {
                    // const ncmDescricao = document.querySelector(
                    //     `datalist option[value="${e.target.value}"]`
                    // )?.value;
                    const arr = document.querySelector(`datalist option[value="${e.target.value}"]`)?.value?.split(" ");

                    setNCM({ ncm: arr[0] });
                }
            }
        } else if (e.target.name.startsWith("Fabricante")) {
            setFabricante({
                ...Fabricante,
                [e.target.name.split(".")[1]]: e.target.value,
            });

            if (e.target.value?.length > 2) {
                let result = await consultaDatalist(
                    token,
                    dados.empresaId,
                    "Pessoa",
                    "id",
                    "descricao",
                    "fabricante",
                    e.target.value
                );
                setOptionsFabricante(result);
            }

            setDados({
                ...dados,
                fabricanteId: document.querySelector(`datalist option[value="${e.target.value}"]`)?.dataset.id,
            });
        } else if (camposMonetarios.includes(e.target.name)) {
            const valorFormatado = formatarNumeroDecimal(e.target.value);
            setDados({ ...dados, [e.target.name]: valorFormatado });
        } else if (e.target.name.startsWith("produtoBens.")) {
            if (e.target.name === "produtoBens.tipo") {
                if (e.target.value === "Móvel") {
                    setDivMovel(true);
                    setDivImovel(false);
                } else {
                    setDivMovel(false);
                    setDivImovel(true);
                }
            }
            if (e.target.name === "produtoBens.cep") {
                setBem({
                    ...produtoBens,
                    [e.target.name.split(".")[1]]: onlyNumbers(e.target.value),
                });
            }
            //para checkbox
            let valor = e.target.type === "checkbox" ? e.target.checked : e.target.value;
            setBem({
                ...produtoBens,
                [e.target.name.split(".")[1]]: valor,
            });

            if (e.target.name === "produtoBens.modiliado" && valor === true) {
                setDivMobilia(true);
            } else {
                setDivMobilia(false);
            }
            // setDados({ ...dados, produtoBens: produtoBens });
        } else if (e.target.name.startsWith("novaEmbalagem.")) {
            // Verifica se é um checkbox e define o valor correspondente
            let valor = e.target.type === "checkbox" ? e.target.checked : e.target.value;

            setNovaEmbalagem({
                ...novaEmbalagem,
                [e.target.name.split(".")[1]]: valor,
                empresa:
                    document.getElementById("novaEmbalagem.empresaId").options[
                        document.getElementById("novaEmbalagem.empresaId").selectedIndex
                    ].text,
                embalagem:
                    document.getElementById("novaEmbalagem.embalagemId").options[
                        document.getElementById("novaEmbalagem.embalagemId").selectedIndex
                    ].text,
            });
        } else if (e.target.name.startsWith("novaMobilia.")) {
            // Verifica se é um checkbox e define o valor correspondente
            let valor = e.target.type === "checkbox" ? e.target.checked : e.target.value;

            if (e.target.name === "novaMobilia.valor") {
                valor = formatarNumeroDecimal(valor);
            }

            setNovaMobilia({
                ...novaMobilia,
                produtoBensId: produtoBens.id,
                [e.target.name.split(".")[1]]: valor,
                ambiente:
                    document.getElementById("novaMobilia.ambienteId").options[
                        document.getElementById("novaMobilia.ambienteId").selectedIndex
                    ].text,
            });
        } else {
            setDados({ ...dados, [e.target.name]: e.target.value });
        }
    }
    async function handleOnBlur(e) {
        if (e.target.name === "descricao") {
            //verifica duplicidade
            if (e.target.name === "descricao" && btnText === "Cadastrar") {
                //verifica se o centroCusto existe na base de dados
                const duplicidade = await TabelaAPI.duplicidadeDescricao(
                    document.getElementById("empresaId").value,
                    "Produto",
                    e.target.value
                );

                if (duplicidade?.uuid) {
                    setFlashMessage("Registro em duplicidade!", "warning");
                    return navigate(`/Produtos/CreateOrEdit`, {
                        uuid: duplicidade.uuid,
                    });
                }
            }
        }
        //localiza endereço
        if (e.target.name === "produtoBens.cep") {
            if (e.target.value) {
                produtoBens.cep = onlyNumbers(e.target.value);
            }

            if (produtoBens.cep?.length === 8) {
                consultaCEP(produtoBens.cep, token);
            } else if (produtoBens.cep?.length > 0 && produtoBens.cep?.length !== 8) {
                setFlashMessage("CEP inválido!!", "error");
            }
        }
    }
    async function handleOnFocus(e) {
        if (e.target.name === "produtoBens.descricao") {
            setBem({
                ...produtoBens,
                descricao: dados.descricao,
            });
        }
    }

    const submit = async (e) => {
        e.preventDefault();

        if (!dados.empresaId) {
            dados.empresaId = parseInt(document.getElementById("empresaId").value);
        }

        //validações
        if (!dados.ncmId) {
            setFlashMessage("NCM é um campo obrigatório!", "error");
            return;
        }

        handleSubmit(dados);
    };

    //data and fetching state
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    //table state
    const [columnFilterFns, setColumnFiltersMode] = useState(
        localStorage.columnFilterFns.length > 2 ? JSON.parse(localStorage.columnFilterFns) : []
    );
    const [columnFilters, setColumnFilters] = useState(
        localStorage.columnFilters.length > 2 ? JSON.parse(localStorage.columnFilters) : []
    );
    const [globalFilter, setGlobalFilter] = useState(
        localStorage.globalFilter.length > 2 ? JSON.parse(localStorage.globalFilter) : ""
    );
    const [sorting, setSorting] = useState(localStorage.sorting.length > 2 ? JSON.parse(localStorage.sorting) : []);
    const [pagination, setPagination] = useState({
        pageIndex: JSON.parse(localStorage.pagination).pageIndex || 0,
        pageSize: JSON.parse(localStorage.pagination).pageSize || 10,
    });

    /* modo tema */
    const globalTheme = useTheme();
    //const theme = tableTheme(globalTheme);
    const theme = tableThemeCustom();

    const columns = useMemo(
        () => [
            {
                id: "inventario",
                header: "Itens do Inventário",
                columns: [
                    { accessorKey: "Ambiente.descricao", id: "Ambiente.descricao", header: "Ambiente" },
                    {
                        accessorKey: "descricao",
                        id: "descricao",
                        header: "Descrição",
                        //you can access a cell in many callback column definition options like this
                        muiTableBodyCellProps: ({ cell }) => ({
                            onClick: () => {},
                        }),
                    },
                    {
                        accessorKey: "valor",
                        header: "Valor",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.valorUnitario === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.valor)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "ativo",
                        header: "Ativo",
                        columnFilterModeOptions: ["equals"],
                        filterSelectOptions: [
                            { text: "Todos", value: "" },
                            { text: "Ativo", value: 1 },
                            { text: "Inativo", value: 0 },
                        ],
                        filterVariant: "select",
                        muiTableBodyCellProps: ({ row }) => ({
                            align: "center",
                            onClick: () => {},
                        }),
                        Cell: ({ row }) => {
                            return row.original.ativo ? "Ativo" : "Inativo";
                        },
                    },
                ],
            },

            //column definitions...
        ],
        [itensInventario] //getCommonEditTextFieldProps
    );

    const handleEditar = async (originalRow, row) => {
        // Define os dados para o novo produto
        const novaMobiliaData = {
            uuid: originalRow.uuid,
            Produto: originalRow.Produto,
            Ambiente: originalRow.Ambiente,

            produtoBensId: originalRow.produtoBensId,
            ambienteId: originalRow.ambienteId,
            valor: originalRow.valor,
            descricao: originalRow.descricao,

            ativo: originalRow.ativo,
            funcao: "editar",
            index: row,
        };

        // Atualize o estado do novo produto
        setNovaMobilia(novaMobiliaData);

        return;
    };
    const handleDelete = (originalRow, row) => {
        const result = {
            uuid: originalRow.uuid,

            produtoBensId: originalRow.produtoBensId,
            ambienteId: originalRow.ambienteId,
            valor: originalRow.valor,
            descricao: originalRow.descricao,

            ativo: originalRow.ativo,
            funcao: "deletar",
            index: row,
        };

        // Atualize o estado
        setNovaMobilia(result);
    };

    useEffect(() => {
        if (!token) {
            return navigate("/Auth", { replace: true });
        }

        //funcao para setar localStorage
        localStorage.setItem(
            "pagination",
            JSON.stringify({
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize,
            })
        );
        localStorage.setItem("columnFilterFns", JSON.stringify(columnFilterFns));
        localStorage.setItem("columnFilters", JSON.stringify(columnFilters));
        localStorage.setItem("globalFilter", JSON.stringify(globalFilter));
        localStorage.setItem("sorting", JSON.stringify(sorting));

        //permissões do usuario
        obterPermissoesUsuario(localStorage.getItem("pessoaId"), localStorage.getItem("empresaId"), ul, li);

        fetchData("inventario");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, columnFilterFns, columnFilters, globalFilter, pagination.pageIndex, pagination.pageSize, sorting]);

    const fetchData = async (grid) => {
        if (!itensInventario.length) {
            setIsLoading(true);
        } else {
            setIsRefetching(true);
        }
        if (dados.id) {
            if (grid === "inventario") {
                try {
                    ProdutoAPI.listarInventario(
                        pagination.pageIndex * pagination.pageSize,
                        pagination.pageSize,
                        JSON.stringify(columnFilters ?? []),
                        JSON.stringify(columnFilterFns ?? []),
                        globalFilter ?? "",
                        JSON.stringify(sorting ?? []),
                        produtoBens.id ?? ""
                    ).then((response) => {
                        setItensInventario(response.data.data);
                        setRowCount(response.data.meta.totalRowCount);
                    });
                } catch (error) {
                    setIsError(true);
                    console.error(error);
                    return;
                }
            }
        }

        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
    };

    return (
        <Tabs>
            <form onSubmit={submit}>
                <h1>Gestão de Produtos</h1>
                <TabList>
                    <Tab disabled={!tabEnabled.produto} title={"Infomações genericas do produto"}>
                        Produto
                    </Tab>
                    <Tab
                        disabled={!tabEnabled.industria}
                        title={"Informações relevantes no processo de industrialização do produto"}
                    >
                        Indústria
                    </Tab>
                    <Tab disabled={!tabEnabled.embalagens} title={"Embalagens do produto"}>
                        Embalagens
                    </Tab>
                    <Tab disabled={!tabEnabled.caracteristicas} title={"Caracteristicas do produto"}>
                        Caracteristicas
                    </Tab>
                    <Tab disabled={!tabEnabled.bens} title={"Informações do bem móvel ou imóvel"}>
                        Bens
                    </Tab>
                    <Tab disabled={!tabEnabled.imagens} title={"Imagens do produto"}>
                        Imagens
                    </Tab>
                </TabList>
                <TabPanel>
                    <div className={formStyles.preview_bandeira}>
                        <h4>Informações principais do Produto</h4>
                    </div>
                    <div>
                        <SelectEmpresa
                            name="empresaId"
                            text="Empresa"
                            options={OptionsEmpresa}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.empresaId}
                            isEnum={false}
                            notOptNull={true}
                        />
                        <Input
                            text="Data Cadastro"
                            type="datetime-local"
                            name="createdAt"
                            placeholder="última atualização"
                            title="Data de inserção do cadastro no sistema"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={(dados.createdAt && retornaDataHora(dados.createdAt)) || retornaDataHora(moment())}
                            readonly={true}
                        />
                        <Input
                            text="Data Atualização"
                            type="datetime-local"
                            name="updatedAt"
                            placeholder="última atualização"
                            title="Data da última atualização dos dados"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={(dados.updatedAt && retornaDataHora(dados.updatedAt)) || retornaDataHora(moment())}
                            readonly={true}
                        />
                        <Checkbox
                            title="Ativa ou Inativa o produto"
                            text="Ativo"
                            name="ativo"
                            type="checkbox"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.ativo}
                            checked={dados.ativo === true ? "checked" : ""}
                        />
                        <Checkbox
                            title="Informa que o produto utiliza a rotina de compras"
                            text="Compra"
                            name="compra"
                            type="checkbox"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.compra}
                            checked={dados.compra === true ? "checked" : ""}
                        />
                        <Checkbox
                            title="Informa que o produto utiliza a rotina de vendas"
                            text="Venda"
                            name="venda"
                            type="checkbox"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.venda}
                            checked={dados.venda === true ? "checked" : ""}
                        />
                        <Checkbox
                            title="Informa que o produto pode ser locado"
                            text="Locavel"
                            name="locavel"
                            type="checkbox"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.locavel}
                            checked={dados.locavel === true ? "checked" : ""}
                        />
                    </div>
                    <div>
                        <Input
                            text="Código"
                            type="text"
                            name="codigo"
                            placeholder="Código"
                            title="O sistema vai gerar um código para o produto"
                            minlength={14}
                            maxlength={18}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.codigo || ""} //posso colocar para gerar um código
                            readonly={true}
                            inputWidth={"150px"}
                        />
                        <Input
                            text="Descrição"
                            type="text"
                            name="descricao"
                            placeholder="Descrição"
                            title="digite a descrição do produto"
                            inputWidth="350px"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.descricao || ""}
                            readonly={false}
                        />
                        <Select
                            name="tipo"
                            text="Tipo"
                            title="Determina o tipo do produto"
                            options={EnumTipo}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.tipo}
                            isEnum={true}
                        />
                    </div>
                    <div>
                        <Input
                            text="referência"
                            type="text"
                            name="referencia"
                            placeholder="Código de referência"
                            title="Digite o código de referência do produto"
                            minlength={14}
                            maxlength={18}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.referencia || ""}
                            inputWidth={"150px"}
                        />
                        <Input
                            text="Código Fabricante"
                            type="text"
                            name="codigoFabricante"
                            placeholder="Código Fabricante"
                            title="Digite o código do fabricante do produto"
                            minlength={14}
                            maxlength={18}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.codigoFabricante || ""}
                            inputWidth={"150px"}
                        />
                        <DataList
                            text="Fabricante"
                            title={"Vincula o Fabricante ao produto"}
                            name="Fabricante.descricao"
                            inputWidth="350px"
                            filteredOptions={OptionsFabricante}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={Fabricante.descricao || ""}
                        />
                    </div>
                    <div>
                        <Input
                            text="NCM"
                            type="text"
                            name="NCM.ncm"
                            placeholder="NCM"
                            inputWidth="150px"
                            title="Código do ncm do produto"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={NCM?.ncm}
                            readonly={true}
                        />
                        <DataList
                            text="NCM Descrição"
                            title={"Vincula o NCM ao produto"}
                            name="NCM.descricao"
                            placeholder={"Digite mais de 3 caracteres para pesquisar"}
                            inputWidth="510px"
                            filteredOptions={OptionsNCM}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={NCM?.descricao}
                            required={true}
                        />
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.preview_bandeira}>
                        <h4>Informações relevantes no processo de industrialização do produto</h4>
                    </div>

                    <div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "1rem",
                            }}
                        >
                            <InputNumber
                                text="Peso Molecular"
                                type="text"
                                name="pesoMolecular"
                                placeholder="peso molecular"
                                inputWidth="160px"
                                title="digite o peso molecular do produto"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.pesoMolecular ? formatarNumero(dados.pesoMolecular) : ""}
                            />
                            <InputNumber
                                text="Tolerância mínima"
                                type="text"
                                name="toleranciaMinima"
                                placeholder="peso em gramas"
                                inputWidth="160px"
                                title="digite a tolerância mínima em razão do peso molecular"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.toleranciaMinima ? formatarNumero(dados.toleranciaMinima) : ""}
                            />
                            <InputNumber
                                text="Tolerância máxima"
                                type="text"
                                name="toleranciaMaxima"
                                placeholder="Tolerência máxima"
                                inputWidth="160px"
                                title="digite a tolerância máxima em razão do peso molecular"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.toleranciaMaxima ? formatarNumero(dados.toleranciaMaxima) : ""}
                            />
                            <InputNumber
                                text="Produção Mínima"
                                type="text"
                                name="producaoMinima"
                                placeholder="peso em gramas"
                                inputWidth="160px"
                                title="valor mínimo de produção do produto"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.producaoMinima ? formatarNumero(dados.producaoMinima) : ""}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "1rem",
                            }}
                        >
                            <Checkbox
                                title="Informa que é uma embalagem"
                                text="Embalagem"
                                name="embalagem"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.embalagem}
                                checked={dados.embalagem === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Informa que o produto é laminado"
                                text="Laminado"
                                name="laminado"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.laminado}
                                checked={dados.laminado === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Informa que o produto é sanfonado"
                                text="Sanfonado"
                                name="sanfonado"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.sanfonado}
                                checked={dados.sanfonado === true ? "checked" : ""}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "1rem",
                            }}
                        >
                            <Checkbox
                                title="Informa que o produto utiliza a rotina de separação"
                                text="Separação"
                                name="separacao"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.separacao}
                                checked={dados.separacao === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Informa que o produto gera refugo"
                                text="Refugo"
                                name="refugo"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.refugo}
                                checked={dados.refugo === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Informa que o produto utiliza a rotina de lavagem"
                                text="Lavagem"
                                name="lavagem"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.lavagem}
                                checked={dados.lavagem === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Informa que o produto utiliza a rotina de produção"
                                text="Produção"
                                name="producao"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.producao}
                                checked={dados.producao === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Informa que o produto utiliza a rotina de extrusão"
                                text="Extrusão"
                                name="extrusao"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.extrusao}
                                checked={dados.extrusao === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Informa que o prcesso de produção do produto finaliza na extrusão"
                                text="Finaliza Extrusão"
                                name="finalizaExtrusora"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.finalizaExtrusora}
                                checked={dados.finalizaExtrusora === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Informa que o produto utiliza da Recuperadora"
                                text="Recuperadora"
                                name="recuperadora"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.recuperadora}
                                checked={dados.recuperadora === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Informa que o produto utiliza da corrugadora"
                                text="Corrugadora"
                                name="corrugadora"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.corrugadora}
                                checked={dados.corrugadora === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Informa que o produto utiliza a rotina da impressora"
                                text="Impressora"
                                name="impressora"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.impressora}
                                checked={dados.impressora === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Informa que o produto utiliza a rotina da corte e solda"
                                text="Corte Solda"
                                name="corteSolda"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.corteSolda}
                                checked={dados.corteSolda === true ? "checked" : ""}
                            />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.preview_bandeira}>
                        <h4>Embalagens do produto</h4>
                    </div>
                    <div>
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "1rem",
                                }}
                            >
                                <SelectEmpresa
                                    name="novaEmbalagem.empresaId"
                                    text="Empresa"
                                    options={OptionsEmpresa}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novaEmbalagem.empresaId}
                                    isEnum={false}
                                    notOptNull={true}
                                />
                                <Select
                                    name="novaEmbalagem.embalagemId"
                                    text="Embalagem"
                                    options={OptionsEmbalagem}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novaEmbalagem.embalagemId}
                                    isEnum={false}
                                    notOptNull={true}
                                />
                                <Input
                                    text="Código de Barras"
                                    type="text"
                                    name="novaEmbalagem.codigoBarras"
                                    placeholder="Código de Barras"
                                    title="digite o código de barras do produto"
                                    inputWidth="200px"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novaEmbalagem.codigoBarras || ""}
                                    readonly={false}
                                />
                                <Checkbox
                                    title="Ativo quando marcado"
                                    text="Ativo"
                                    name="novaEmbalagem.ativo"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novaEmbalagem.ativo}
                                    checked={novaEmbalagem.ativo === true ? "checked" : ""}
                                />{" "}
                                <Button
                                    variant="contained"
                                    color="inherit"
                                    onClick={() =>
                                        setNovaEmbalagem({
                                            uuid: "",
                                            empresaId: dados.empresaId,
                                            produtoId: dados.id,
                                            embalagemId: "",
                                            codigoBarras: "",
                                            ativo: false,
                                            funcao: "adicionar",
                                        })
                                    }
                                    style={{ marginLeft: "10px" }}
                                >
                                    Limpar
                                </Button>
                                <Button
                                    variant="contained"
                                    color={novaEmbalagem.funcao === "deletar" ? "error" : "info"}
                                    onClick={() => handleEmbalagem(novaEmbalagem)}
                                    disabled={novaEmbalagem.codigoBarras?.length < 1 ? true : false}
                                    style={{ marginLeft: "5px" }}
                                >
                                    {novaEmbalagem.funcao}
                                </Button>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "1rem",
                                }}
                            ></div>
                        </div>
                        <div>
                            <table className={`${formStyles.table} ${formStyles[parametro.tema]}`}>
                                <thead>
                                    <tr>
                                        <th className={formStyles.th} align="left">
                                            Empresa
                                        </th>
                                        <th className={formStyles.th} align="left">
                                            Embalagem
                                        </th>
                                        <th className={formStyles.th} align="left">
                                            Código de Barra
                                        </th>
                                        <th className={formStyles.th} align="center">
                                            Ações
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className={formStyles.tbody}>
                                    <>
                                        {Object?.keys(produtoEmbalagens)?.map((key, index, array) => (
                                            <tr className={formStyles.tr} key={produtoEmbalagens[key].uuid || index}>
                                                <td
                                                    className={formStyles.td}
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >{`${
                                                    produtoEmbalagens[key]?.empresa ||
                                                    produtoEmbalagens[key]?.Empresa?.nomeFantasia
                                                }`}</td>
                                                <td
                                                    className={formStyles.td}
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >{`${
                                                    produtoEmbalagens[key]?.embalagem ||
                                                    produtoEmbalagens[key]?.Embalagem?.descricao
                                                }`}</td>
                                                <td
                                                    className={formStyles.td}
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >{`${produtoEmbalagens[key]?.codigoBarras}`}</td>
                                                <td className={formStyles.td}>
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={() =>
                                                            setNovaEmbalagem({
                                                                index: index,
                                                                uuid: produtoEmbalagens[key].uuid,
                                                                produtoId: produtoEmbalagens[key].produtoId,

                                                                empresaId: produtoEmbalagens[key].empresaId,
                                                                empresa:
                                                                    document.getElementById("novaEmbalagem.empresaId").options[
                                                                        document.getElementById("novaEmbalagem.empresaId")
                                                                            .selectedIndex
                                                                    ].text,
                                                                embalagemId: produtoEmbalagens[key].embalagemId,
                                                                embalagem: produtoEmbalagens[key].embalagem,
                                                                codigoBarras: produtoEmbalagens[key].codigoBarras,
                                                                ativo: produtoEmbalagens[key].ativo,
                                                                funcao: "editar",
                                                            })
                                                        }
                                                    >
                                                        Editar
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        color="error"
                                                        onClick={() =>
                                                            setNovaEmbalagem({
                                                                index: index,
                                                                uuid: produtoEmbalagens[key].uuid,
                                                                produtoId: produtoEmbalagens[key].produtoId,

                                                                empresaId: produtoEmbalagens[key].empresaId,
                                                                embalagemId: produtoEmbalagens[key].embalagemId,
                                                                codigoBarras: produtoEmbalagens[key].codigoBarras,
                                                                ativo: produtoEmbalagens[key].ativo,
                                                                funcao: "deletar",
                                                            })
                                                        }
                                                        style={{
                                                            marginLeft: "5px",
                                                        }}
                                                    >
                                                        Excluir
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.preview_bandeira}>
                        <h4>Informações do produto</h4>
                    </div>
                    <div>
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "1rem",
                                }}
                            >
                                <InputNumber
                                    text="Peso Bruto"
                                    type="text"
                                    name="pesoBruto"
                                    placeholder="peso em Kg"
                                    inputWidth="100px"
                                    title="digite o peso bruto do produto"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.pesoBruto ? formatarNumero(dados.pesoBruto) : ""}
                                />
                                <InputNumber
                                    text="Peso Líquido"
                                    type="text"
                                    name="pesoLiquido"
                                    placeholder="peso em Kg"
                                    inputWidth="100px"
                                    title="digite o peso líquido do produto"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.pesoLiquido ? formatarNumero(dados.pesoLiquido) : ""}
                                />
                                <InputNumber
                                    text="Estoque Mínimo"
                                    type="text"
                                    name="estoqueMinimo"
                                    placeholder="Estoque mínimo operacional"
                                    inputWidth="100px"
                                    title="digite o valor do estoque mínimo do produto"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.estoqueMinimo ? formatarNumero(dados.estoqueMinimo) : ""}
                                />
                            </div>
                            <div>
                                <InputNumber
                                    text="Comissão"
                                    type="text"
                                    name="comissao"
                                    placeholder="Comissão do produto no movimento"
                                    inputWidth="100px"
                                    title="digite o valor do comissão do produto"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.comissao ? formatarNumero(dados.comissao) : ""}
                                />
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.preview_bandeira}>
                        <h4>Bem</h4>
                        <div className={formStyles.preview_bandeira}>
                            <div>
                                <Input
                                    text="Patrimônio"
                                    type="text"
                                    name="produtoBens.patrimonio"
                                    placeholder=""
                                    title="Número do patrimônio"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={produtoBens.patrimonio || ""}
                                    inputWidth={"150px"}
                                />
                                <Input
                                    text="Descrição do BEM"
                                    type="text"
                                    name="produtoBens.descricao"
                                    placeholder="Descrição"
                                    title="digite a descrição do BEM"
                                    inputWidth="350px"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={produtoBens.descricao || ""}
                                    readonly={false}
                                />
                                <Select
                                    name="produtoBens.tipo"
                                    text="Tipo"
                                    title="Determina o tipo do produto"
                                    options={EnumBemTipo}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={produtoBens.tipo}
                                    isEnum={true}
                                />
                                <Checkbox
                                    title="Ativa ou Inativa o BEM"
                                    text="Ativo"
                                    name="produtoBens.ativo"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={produtoBens.ativo}
                                    checked={produtoBens.ativo === true ? "checked" : ""}
                                />
                            </div>
                            <div>
                                <DataList
                                    text="Proprietário"
                                    name="Proprietario.descricao"
                                    placeholder={"Proprietário do bem"}
                                    title={"Proprietário do bem, poderá ser vinculado ao contrato de locação do bem"}
                                    inputWidth="400px"
                                    filteredOptions={OptionsProprietario}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={Proprietario?.descricao || ""}
                                />
                            </div>
                        </div>
                        {divImovel && (
                            <div id="divImovel">
                                <h4>Bem IMÓVEL</h4>
                                <div className={formStyles.preview_bandeira}>
                                    <div>
                                        <Input
                                            text="Inscrioção Municipal"
                                            type="text"
                                            name="produtoBens.inscricaoMunicipal"
                                            placeholder=""
                                            title="Número da inscrição municipal"
                                            handleOnChange={handleChange}
                                            handleOnFocus={handleOnFocus}
                                            handleOnBlur={handleOnBlur}
                                            value={produtoBens.inscricaoMunicipal || ""}
                                            inputWidth={"150px"}
                                        />
                                        <Input
                                            text="Matrícula"
                                            type="text"
                                            name="produtoBens.matricula"
                                            placeholder=""
                                            title="Número da matrícula do imóvel"
                                            handleOnChange={handleChange}
                                            handleOnFocus={handleOnFocus}
                                            handleOnBlur={handleOnBlur}
                                            value={produtoBens.matricula || ""}
                                            inputWidth={"150px"}
                                        />
                                        <Input
                                            text="Contador água"
                                            type="text"
                                            name="produtoBens.contadorAgua"
                                            placeholder=""
                                            title="Número do contador de água do imóvel"
                                            handleOnChange={handleChange}
                                            handleOnFocus={handleOnFocus}
                                            handleOnBlur={handleOnBlur}
                                            value={produtoBens.contadorAgua || ""}
                                            inputWidth={"150px"}
                                        />
                                        <Input
                                            text="Contador energia"
                                            type="text"
                                            name="produtoBens.contadorEnergia"
                                            placeholder=""
                                            title="Número do contador de energia do imóvel"
                                            handleOnChange={handleChange}
                                            handleOnFocus={handleOnFocus}
                                            handleOnBlur={handleOnBlur}
                                            value={produtoBens.contadorEnergia || ""}
                                            inputWidth={"150px"}
                                        />
                                        <Checkbox
                                            title="Ativa ou Inativa o BEM"
                                            text="Mobiliado"
                                            name="produtoBens.mobiliado"
                                            type="checkbox"
                                            handleOnChange={handleChange}
                                            handleOnFocus={handleOnFocus}
                                            handleOnBlur={handleOnBlur}
                                            value={produtoBens.mobiliado}
                                            checked={produtoBens.mobiliado === true ? "checked" : ""}
                                        />
                                    </div>
                                    <div>
                                        <Input
                                            text="CEP"
                                            type="text"
                                            name="produtoBens.cep"
                                            maxlength={10}
                                            placeholder="CEP"
                                            title="Digite o CEP do imóveel"
                                            handleOnChange={handleChange}
                                            handleOnFocus={handleOnFocus}
                                            handleOnBlur={handleOnBlur}
                                            value={produtoBens.cep ? maskCEP(produtoBens.cep) : ""}
                                            inputWidth="100px"
                                        />
                                        <Select
                                            text="Selecione o Estado"
                                            name="produtoBens.ufId"
                                            options={optionsUF}
                                            handleOnChange={handleChange}
                                            handleOnFocus={handleOnFocus}
                                            handleOnBlur={handleOnBlur}
                                            value={produtoBens.ufId || ""}
                                            isEnum={false}
                                        />
                                        <Select
                                            text="Município"
                                            name="produtoBens.municipioId"
                                            options={optionsMunicipios}
                                            title="Escolha a município"
                                            handleOnChange={handleChange}
                                            handleOnFocus={handleOnFocus}
                                            handleOnBlur={handleOnBlur}
                                            value={produtoBens.municipioId || ""}
                                            isEnum={false}
                                        />
                                        <Input
                                            text="Latitude"
                                            type="text"
                                            name="produtoBens.latitude"
                                            maxlength={10}
                                            placeholder="Latitude do imóvel"
                                            title="Digite a latitude da localização  do imóvel"
                                            handleOnChange={handleChange}
                                            handleOnFocus={handleOnFocus}
                                            handleOnBlur={handleOnBlur}
                                            value={produtoBens.latitude || ""}
                                        />
                                        <Input
                                            text="Longitude"
                                            type="text"
                                            name="produtoBens.longitude"
                                            maxlength={10}
                                            placeholder="Longitude do imóvel"
                                            title="Digite a Longitude da localização  do imóvel"
                                            handleOnChange={handleChange}
                                            handleOnFocus={handleOnFocus}
                                            handleOnBlur={handleOnBlur}
                                            value={produtoBens.longitude || ""}
                                        />
                                    </div>
                                    <div>
                                        <Input
                                            text="Bairro"
                                            type="text"
                                            name="produtoBens.bairro"
                                            placeholder="Bairro"
                                            title="Digite o bairro do imóvel"
                                            inputWidth="350px"
                                            handleOnChange={handleChange}
                                            value={produtoBens.bairro || ""}
                                        />
                                        <Input
                                            text="Logradouro"
                                            type="text"
                                            name="produtoBens.logradouro"
                                            placeholder="Logradouro"
                                            title="Digite o logradouro do imóvel"
                                            inputWidth="350px"
                                            handleOnChange={handleChange}
                                            value={produtoBens.logradouro || ""}
                                        />
                                        <Input
                                            text="Número"
                                            type="text"
                                            name="produtoBens.numero"
                                            placeholder="Número"
                                            title="Digite o número do imóvel"
                                            handleOnChange={handleChange}
                                            value={produtoBens.numero || ""}
                                        />
                                        <Input
                                            text="Complemento"
                                            type="text"
                                            name="produtoBens.complemento"
                                            placeholder="Complemento do imóvel"
                                            title="Digite o complemento do imóvel"
                                            inputWidth="350px"
                                            handleOnChange={handleChange}
                                            value={produtoBens.complemento || ""}
                                        />
                                    </div>
                                </div>
                                {divMobilia && (
                                    <div id="divMobiliado">
                                        <h4>Mobília</h4>
                                        <div className={formStyles.preview_bandeira}>
                                            <div>
                                                <TextArea
                                                    text="Descrição do Bem"
                                                    type="text"
                                                    name="novaMobilia.descricao"
                                                    placeholder="Digite aqui a descrição"
                                                    title="Digite descrição do bem do imóvel"
                                                    handleOnChange={handleChange}
                                                    handleOnFocus={handleOnFocus}
                                                    handleOnBlur={handleOnBlur}
                                                    value={novaMobilia.descricao || ""}
                                                    textAlign="left"
                                                    linhas="4"
                                                    colunas="120"
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    marginTop: "1rem",
                                                    flexWrap: "wrap", // Permite que os itens quebrem a linha quando necessário
                                                    justifyContent: "left", //"space-between", // Distribui os itens igualmente
                                                }}
                                            >
                                                <Select
                                                    name="novaMobilia.ambienteId"
                                                    text="Ambiente"
                                                    options={optionsAmbiente}
                                                    handleOnChange={handleChange}
                                                    handleOnFocus={handleOnFocus}
                                                    handleOnBlur={handleOnBlur}
                                                    value={novaMobilia.ambienteId}
                                                    isEnum={false}
                                                    notOptNull={false}
                                                />
                                                <InputNumber
                                                    text="Valor"
                                                    type="text"
                                                    name="novaMobilia.valor"
                                                    placeholder="Valor R$"
                                                    title="digite o valor em R$"
                                                    handleOnChange={handleChange}
                                                    handleOnFocus={handleOnFocus}
                                                    handleOnBlur={handleOnBlur}
                                                    value={novaMobilia.valor ? formatarNumero(novaMobilia.valor) : ""}
                                                />
                                                <Checkbox
                                                    title="Ativo quando marcado"
                                                    text="Ativo"
                                                    name="novaMobilia.ativo"
                                                    type="checkbox"
                                                    handleOnChange={handleChange}
                                                    handleOnFocus={handleOnFocus}
                                                    handleOnBlur={handleOnBlur}
                                                    value={novaMobilia.ativo}
                                                    checked={novaMobilia.ativo === true ? "checked" : ""}
                                                />
                                                <Button
                                                    variant="contained"
                                                    color="inherit"
                                                    onClick={() => {
                                                        setNovaMobilia({
                                                            uuid: null,
                                                            produtoBensId: produtoBens.id,
                                                            ambienteId: novaMobilia.ambienteId,
                                                            descricao: null,
                                                            valor: null,
                                                            ativo: true,
                                                            funcao: "adicionar",
                                                        });
                                                    }}
                                                    //style={{ marginLeft: "10px" }}
                                                    style={{
                                                        marginLeft: "10px",
                                                        minWidth: "80px",
                                                        fontSize: "14px",
                                                        "@media (max-width: 600px)": {
                                                            fontSize: "12px", // Ajusta o tamanho da fonte em telas menores
                                                            padding: "8px 12px", // Ajusta o padding para manter o botão visível
                                                        },
                                                    }}
                                                >
                                                    Limpar
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color={novaMobilia.funcao === "deletar" ? "error" : "info"}
                                                    onClick={() => {
                                                        handleMobilia(novaMobilia);
                                                    }}
                                                    disabled={!novaMobilia.descricao ? true : false}
                                                    //style={{ marginLeft: "5px" }}
                                                    style={{
                                                        marginLeft: "10px",
                                                        minWidth: "80px",
                                                        fontSize: "14px",
                                                        "@media (max-width: 600px)": {
                                                            fontSize: "12px", // Ajusta o tamanho da fonte em telas menores
                                                            padding: "8px 12px", // Ajusta o padding para manter o botão visível
                                                        },
                                                    }}
                                                >
                                                    {novaMobilia.funcao}
                                                </Button>
                                            </div>
                                        </div>
                                        <ThemeProvider theme={theme}>
                                            <MaterialReactTable
                                                displayColumnDefOptions={{
                                                    "mrt-row-actions": {
                                                        muiTableHeadCellProps: {
                                                            align: "center",
                                                        },
                                                        size: 100,
                                                    },
                                                    "mrt-row-numbers": {
                                                        enableColumnOrdering: true, //turn on some features that are usually off
                                                        enableResizing: true,
                                                        muiTableHeadCellProps: {
                                                            sx: {
                                                                fontSize: "1.2rem",
                                                            },
                                                        },
                                                    },
                                                    "mrt-row-select": {
                                                        enableColumnActions: false,
                                                        enableHiding: true,
                                                        size: 50,
                                                    },
                                                }}
                                                enableColumnFilterModes
                                                enableGlobalFilterModes
                                                enableColumnResizing
                                                enableColumnOrdering
                                                enableEditing
                                                renderRowActions={({ row, table }) => (
                                                    <Box sx={{ display: "flex", gap: "1rem" }}>
                                                        <Tooltip arrow placement="left" title="Editar">
                                                            <span>
                                                                {" "}
                                                                <IconButton
                                                                    onClick={() => {
                                                                        handleEditar(row.original, row.index);
                                                                    }}
                                                                    disabled={
                                                                        localStorage.getItem("editar") === "true" ? false : true
                                                                    }
                                                                >
                                                                    <Edit />
                                                                </IconButton>
                                                            </span>
                                                        </Tooltip>
                                                        <Tooltip arrow placement="right" title="Deletar">
                                                            <span>
                                                                <IconButton
                                                                    color="error"
                                                                    //onClick={() => handleOpenDialog(row.id)}
                                                                    onClick={() => handleDelete(row.original, row.id)}
                                                                    disabled={
                                                                        localStorage.getItem("cancelar") === "true" ? false : true
                                                                    }
                                                                >
                                                                    <Delete />
                                                                </IconButton>
                                                            </span>
                                                        </Tooltip>
                                                    </Box>
                                                )}
                                                // renderTopToolbarCustomActions={() => (
                                                //     <div
                                                //         style={{
                                                //             display: "flex",
                                                //             gap: "8px",
                                                //         }}
                                                //     >
                                                //         <Tooltip arrow placement="top-end" title={`Novo Registro`}>
                                                //             <div>
                                                //                 {" "}
                                                //                 <Button
                                                //                     color="secondary"
                                                //                     onClick={() => navigate("/TabelaPrecos/CreateOrEdit")}
                                                //                     variant="contained"
                                                //                     disabled={
                                                //                         true
                                                //                         // localStorage.getItem("incluir") ===
                                                //                         // "true"
                                                //                         //     ? false
                                                //                         //     : true
                                                //                     }
                                                //                 >
                                                //                     Novo
                                                //                 </Button>
                                                //             </div>
                                                //         </Tooltip>
                                                //     </div>
                                                // )}
                                                columns={columns}
                                                data={itensInventario}
                                                enableRowSelection
                                                getRowId={(row) => row.uuid}
                                                manualFiltering
                                                manualPagination
                                                manualSorting
                                                muiToolbarAlertBannerProps={
                                                    isError
                                                        ? {
                                                              color: "error",
                                                              children: "Error loading data",
                                                          }
                                                        : undefined
                                                }
                                                onColumnFiltersChange={setColumnFilters}
                                                onColumnFilterFnsChange={setColumnFiltersMode}
                                                onGlobalFilterChange={setGlobalFilter}
                                                onPaginationChange={setPagination}
                                                onSortingChange={setSorting}
                                                rowCount={rowCount}
                                                initialState={{
                                                    showColumnFilters: true,
                                                    density: "compact",
                                                    //columnPinning: { left: ["mrt-row-select"] },
                                                }}
                                                state={{
                                                    columnFilterFns,
                                                    columnFilters,
                                                    globalFilter,
                                                    isLoading,
                                                    pagination,
                                                    showAlertBanner: isError,
                                                    showProgressBars: isRefetching,
                                                    sorting,
                                                }}
                                                //positionToolbarDropZone={"top"}
                                                positionExpandColumn={"first"}
                                                positionActionsColumn={"first"}
                                                positionToolbarAlertBanner="bottom"
                                                localization={MRT_Localization_PT_BR}
                                            />
                                        </ThemeProvider>
                                    </div>
                                )}
                            </div>
                        )}
                        {divMovel && (
                            <div id="divMovel">
                                <h4>Bem MÓVEL</h4>
                                <div className={formStyles.preview_bandeira} id="divMovel">
                                    <div>
                                        <DataList
                                            text="Modelo"
                                            title={"Vincula o modelo do BEM"}
                                            name="produtoModelo.descricao"
                                            inputWidth="350px"
                                            filteredOptions={OptionsModelo}
                                            handleOnChange={handleChange}
                                            handleOnFocus={handleOnFocus}
                                            handleOnBlur={handleOnBlur}
                                            value={produtoModelo.descricao || ""}
                                        />
                                        <Select
                                            name="produtoBens.corId"
                                            text="Cor"
                                            options={OptionsCores}
                                            handleOnChange={handleChange}
                                            handleOnFocus={handleOnFocus}
                                            handleOnBlur={handleOnBlur}
                                            value={produtoBens.corId}
                                            isEnum={false}
                                            notOptNull={true}
                                        />
                                        <Input
                                            text="Ano Fabricação"
                                            type="number"
                                            name="produtoBens.anoFabricacao"
                                            placeholder="Ano"
                                            title=""
                                            inputWidth="100px"
                                            handleOnChange={handleChange}
                                            handleOnFocus={handleOnFocus}
                                            handleOnBlur={handleOnBlur}
                                            value={produtoBens.anoFabricacao || ""}
                                        />
                                        <Input
                                            text="Ano Modelo"
                                            type="number"
                                            name="produtoBens.anoModelo"
                                            placeholder="Ano"
                                            title=""
                                            inputWidth="100px"
                                            handleOnChange={handleChange}
                                            handleOnFocus={handleOnFocus}
                                            handleOnBlur={handleOnBlur}
                                            value={produtoBens.anoModelo || ""}
                                        />
                                        <Input
                                            text="Km inicial"
                                            type="number"
                                            name="produtoBens.kmInicial"
                                            placeholder="Km"
                                            title=""
                                            inputWidth="100px"
                                            handleOnChange={handleChange}
                                            handleOnFocus={handleOnFocus}
                                            handleOnBlur={handleOnBlur}
                                            value={produtoBens.kmInicial || ""}
                                        />
                                    </div>
                                    <div>
                                        <Input
                                            text="Placa"
                                            type="text"
                                            name="produtoBens.placa"
                                            placeholder="Placa"
                                            title="digite a descrição do produto"
                                            inputWidth="150px"
                                            handleOnChange={handleChange}
                                            handleOnFocus={handleOnFocus}
                                            handleOnBlur={handleOnBlur}
                                            value={produtoBens.placa || ""}
                                        />
                                        <Input
                                            text="Chassi"
                                            type="text"
                                            name="produtoBens.chassi"
                                            placeholder="CHASSI"
                                            title="digite a descrição do produto"
                                            inputWidth="200px"
                                            handleOnChange={handleChange}
                                            handleOnFocus={handleOnFocus}
                                            handleOnBlur={handleOnBlur}
                                            value={produtoBens.chassi || ""}
                                            readonly={false}
                                        />
                                        <Input
                                            text="Renavan"
                                            type="number"
                                            name="produtoBens.renavan"
                                            placeholder="RENAVAN"
                                            title="digite a descrição do produto"
                                            inputWidth="150px"
                                            handleOnChange={handleChange}
                                            handleOnFocus={handleOnFocus}
                                            handleOnBlur={handleOnBlur}
                                            value={produtoBens.renavan || ""}
                                            readonly={false}
                                        />
                                        <Input
                                            text="CRV"
                                            type="number"
                                            name="produtoBens.crv"
                                            placeholder="CRV"
                                            title="Número do CRV "
                                            inputWidth="150px"
                                            handleOnChange={handleChange}
                                            handleOnFocus={handleOnFocus}
                                            handleOnBlur={handleOnBlur}
                                            value={produtoBens.crv || ""}
                                            readonly={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.preview_bandeira}>
                        <h4>Imagens do Produto</h4>
                    </div>
                    <div className={formStyles.preview_bandeira}>
                        <div>
                            <Input
                                text="Fotos do Produto"
                                type="file"
                                name="images"
                                handleOnChange={onFileChange}
                                inputWidth="400px"
                                multiple={true}
                                accept=".jpg, .jpeg, .png"
                            />
                        </div>
                        {preview.length > 0
                            ? preview.map((image, index) => (
                                  <img
                                      src={URL.createObjectURL(image)}
                                      alt={dados.images}
                                      key={`${dados.images}+${index}`}
                                      style={{
                                          borderColor: "black",
                                          border: "solid 0.05em",
                                      }}
                                      width={60}
                                      height={80}
                                  />
                              ))
                            : produtoImagems &&
                              produtoImagems.map((imagem, index) => (
                                  <img
                                      src={process.env.REACT_APP_API + "imagens/produtos/" + imagem.descricao}
                                      alt={`Imagem ${index + 1}`}
                                      key={imagem.uuid || index}
                                      title={process.env.REACT_APP_API + "imagens/produtos/" + imagem.descricao}
                                      style={{
                                          borderColor: "black",
                                          border: "solid 0.05em",
                                          margin: "2px",
                                      }}
                                      loading="lazy"
                                      width={60}
                                      height={80}
                                  />
                              ))}
                    </div>
                </TabPanel>
                <div>
                    <Stack direction="row" spacing={1}>
                        <Button variant="contained" color="secondary" onClick={() => navigate(caminhoVoltar)}>
                            Voltar
                        </Button>
                        <Button type="submit" variant="contained" color="success">
                            {btnText}
                        </Button>
                    </Stack>
                </div>
            </form>
        </Tabs>
    );
}

export default Form;
