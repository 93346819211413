import { useContext } from "react";
import { Context } from "../../context/UserContext";
import { height, width } from "@mui/system";
import styles from "./TextArea.module.css";

function TextArea({
    type,
    text,
    name,
    placeholder,
    handleOnChange,
    handleOnBlur,
    handleOnFocus,
    value,
    title,
    required,
    readonly,
    min,
    max,
    textAlign,
    linhas = 5,
    colunas = 80,
    //width = "100%",
    //height = "auto",
}) {
    //configura o tema
    const { parametro } = useContext(Context);

    return (
        <div className={`${styles.form_control} ${styles[parametro.tema]}`}>
            <label htmlFor={name}>{text}:</label>
            <textarea
                type={type}
                name={name}
                id={name}
                placeholder={placeholder}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onFocus={handleOnFocus}
                title={title}
                required={required}
                style={{
                    textAlign: textAlign,
                    //width: "100%",
                    //height: "auto",
                }}
                readOnly={readonly === true ? true : false}
                min={min}
                max={max}
                cols={colunas}
                rows={linhas}
                value={value}
            />
        </div>
    );
}

export default TextArea;
